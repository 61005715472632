import React from "react";
import BackIMg from "../Assets/Images/back-icon.svg";
import { Row, Col } from "react-bootstrap";
import "../Styles/ViewTicket.css";
import ProfileImg from "../Assets/Images/Ava.png";
import ActionImg from "../Assets/Images/action-img.svg";
import { useParams,Link } from "react-router-dom";
import {useGetTicketbyIdQuery} from "../Services/SupportAPI"

function ViewTicket() {
  const { id } = useParams(); 
const {data} = useGetTicketbyIdQuery({id})

  return (
    <div className="view-ticket">
      <h4>
      <Link to="/dashboard"><img src={BackIMg} /></Link>  
        Ashley Stracke
      </h4>
      <div className="content-wrapper">
        <div className="d-flex justify-space-between">
          <div className="d-flex">
            <div>
              <img src={ProfileImg} alt="ProfileImg" />
            </div>
            <div className="mgl-20">
              <p className="person-name">Golanginya</p>
              <p className="ticket-date">12 November 2020 19:35</p>
            </div>
          </div>
          <div>
            <img src={ActionImg} alt="ActionImg" />
          </div>
        </div>
        <Row className="ticket-sub">
          <p>
            Lorem ipsum dolor sit amet consectetur. Dignissim tincidunt sed
            vitae vehicula. Congue lacus magnis viverra risus aliquam enim. Sed
            nunc rhoncus nisl interdum augue mattis lacinia adipiscing mauris.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur. Dignissim tincidunt sed
            vitae vehicula. Congue lacus magnis viverra risus aliquam enim. Sed
            nunc rhoncus nisl interdum augue mattis lacinia adipiscing mauris.
          </p>
        </Row>
        <Row>
          <form>
            <div className="form-control">
              <input type="text" placeholder="Type your message here ..." />
            </div>
            <div className="text-end">
              <button type="button" className="btn-outline mgr-10">Cancel</button>
              <button type="submit" className="btn-primary">Comment</button>
            </div>
          </form>
        </Row>
      </div>
    </div>
  );
}

export default ViewTicket;
