import React, { useState , useEffect} from 'react';
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import ProfileIMg from "../Assets/Images/default-profile-img.png"
import Notification from "../Assets/Images/notification.svg"
import NotificationText from "../Assets/Images/notification-text.svg"
import SupportImg from "../Assets/Images/support_nav.svg"
import DropdownImg from "../Assets/Images/Dropdown.svg"
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import {useNotificationMarkAllReadMutation,
  useNotificationMarkReadBusinessMaterialMutation,
  useNotificationMarkReadDirectoryMutation,
  useNotificationMarkReadEcosystemMutation,
  useNotificationMarkReadTechnicalMutation,
  useNotificationMarkReadWebsiteMutation,} from "../Services/UserAPI"


const AppNavbar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState(''); // State to hold selected option
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setShowDropdown(false); // Close dropdown after selecting an option
  };
const handleLogout = ()=>{
  localStorage.clear();
  navigate("/");
}

const [notifications, setNotifications] = useState([]);
const token = localStorage.getItem('SupportAccessToken');
const supportRole = localStorage.getItem('supportRole');

console.log("supportRole", supportRole)
const websocketUrl = `wss://${process.env.REACT_APP_CORE_SERVER_WEBSOCKET}ws/external/custom_${
  supportRole === 'Technical support' ? 'technical_support_admin' :
  supportRole === 'Website support' ? 'website_support_admin' :
  supportRole === 'Directory support' ? 'directory_support_admin' :
  supportRole === 'Ecosystem support' ? 'ecosystem_support_admin' :
  supportRole === 'Business material support' ? 'business_material_support_admin' :
  'support_admin'
}/portal-notifications/unread-count/?token=${token}`;

useEffect(() => {
  const socket = new WebSocket(websocketUrl);


  socket.onopen = () => {
    console.log("WebSocket connection established");
  };

  socket.onmessage = (event) => {
    const notification = JSON.parse(event.data);
    console.log("Received notification:", notification);
    setNotifications(notification);
  };

  socket.onclose = () => {
    console.log("WebSocket connection closed");
  };

  return () => {
    socket.close();
  };
}, [websocketUrl]);

const [markAllReadGeneral] = useNotificationMarkAllReadMutation();
const [markReadBusinessMaterial] = useNotificationMarkReadBusinessMaterialMutation();
const [markReadDirectory] = useNotificationMarkReadDirectoryMutation();
const [markReadEcosystem] = useNotificationMarkReadEcosystemMutation();
const [markReadTechnical] = useNotificationMarkReadTechnicalMutation();
const [markReadWebsite] = useNotificationMarkReadWebsiteMutation();

const handleMarkAllRead = async (e) => {
  let submitForm;

  // Determine the appropriate mutation based on the support role
  switch (supportRole) {
    case 'Technical support':
      submitForm = markReadTechnical;
      break;
    case 'Website support':
      submitForm = markReadWebsite;
      break;
    case 'Directory support':
      submitForm = markReadDirectory;
      break;
    case 'Ecosystem support':
      submitForm = markReadEcosystem;
      break;
    case 'Business material support':
      submitForm = markReadBusinessMaterial;
      break;
    default:
      submitForm = markAllReadGeneral;
      break;
  }

  // Attempt to execute the selected mutation
  try {
    const response = await submitForm().unwrap();
    console.log("All notifications marked as read:", response);
  } catch (err) {
    console.error("Failed to mark notifications as read:", err);
  }
};
  return (
    <Navbar  variant="dark" expand="lg" className="dashboard-nav">
      <Navbar.Brand href="#home">Navbar</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">   
        <Nav className="ml-auto">
        <Link to="/notification">
          <div className="ecosystem-notification-div">
          <img src={Notification} alt="Notification" className='notification-img' onClick={handleMarkAllRead}/>
         {notifications?.unread_count>0 &&<div className="notification-count-cafe">
                {notifications?.unread_count}
              </div>}
          </div>
          </Link>
          {/* <Nav.Link href="#">
            <img src={ProfileIMg} alt="ProfileIMg"/><span>Admin</span>
          </Nav.Link> */}
            <div className="profile-dropdown">
            <Dropdown align="end">
              <Dropdown.Toggle
                as={Nav.Link}
                id="profile-dropdown"
                className="d-flex align-items-center"
              >
                <img
                  src={ProfileIMg}
                  alt="ProfileIMg"
                  className="nav-profile-img"
                  width={30}
                  height={30}
                />
                <span className="ms-2">Support</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AppNavbar;
