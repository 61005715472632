import React, {useEffect, useState} from "react";
import "../Styles/EcosystemNotification.css";
import { Row, Col } from "react-bootstrap";
import moment from 'moment';

function Ecosystemnotification() {
  const [notifications, setNotifications] = useState([]);
  const token = localStorage.getItem('SupportAccessToken');
  const supportRole = localStorage.getItem('supportRole');

const websocketUrl = `wss://${process.env.REACT_APP_CORE_SERVER_WEBSOCKET}ws/external/custom_${
  supportRole === 'Technical support' ? 'technical_support_admin' :
  supportRole === 'Website support' ? 'website_support_admin' :
  supportRole === 'Directory support' ? 'directory_support_admin' :
  supportRole === 'Ecosystem support' ? 'ecosystem_support_admin' :
  supportRole === 'Business material support' ? 'business_material_support_admin' :
  'support_admin'
}/portal-notifications/?token=${token}`;

  function formatDate(dateString) {
    return moment(dateString).format('DD/MM/YYYY');
  }

  useEffect(() => {
      // Create a new WebSocket connection
      const socket = new WebSocket(websocketUrl);

      // Handle connection open
      socket.onopen = () => {
          console.log("WebSocket connection established");
      };

      // Handle incoming messages
      socket.onmessage = (event) => {
          const notification = JSON.parse(event.data);
          console.log("Received notification:", notification); // Log the received notification
          setNotifications((prevNotifications) => [notification, ...prevNotifications]); // Prepend new notifications
      };

      // Handle connection close
      socket.onclose = () => {
          console.log("WebSocket connection closed");
      };

      // Clean up the socket connection on unmount
      return () => {
          socket.close();
      };
  }, [websocketUrl]);
  return (
    <div className="ecosystem-notification">
    <h4>Notifications</h4>
    <p className="sub-head">Stay updated with the latest alerts and messages.</p>
    {notifications.map((notification) => ( <div className="notification-list">
      <div className="card">
        <h6>
        {notification.title}
        </h6>
        <p>
        {notification.message}
        </p>
        <div className="text-end">
          <p className="time">({formatDate(notification.created_at ?? "")})</p>
        </div>
      </div>
    </div>))}
  </div>
  );
}

export default Ecosystemnotification;
