import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "../Styles/Dashboard.css";
import Logo from "../Assets/Images/logo.svg";
import React, { useState, useEffect } from "react";
import MediaGallery from "../Assets/Images/media-gallery.svg";
import { Link , useNavigate} from "react-router-dom";
import {
  useRefreshtokenMutation
} from "../Services/SupportLoginAPI"


function SidebarComponent({ handleChange }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [submitRefreshToken, { isSuccessRefreshToken, isError: isErrorRefreshToken }] =
  useRefreshtokenMutation();
const [refreshToken, setRefreshToken] = useState("");
const [refreshtokenNew, setRefreshTokenNew] = useState("");
const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  const handleSubmitRefreshToken = async () => {
    let postData = {
      refresh :localStorage.getItem("SupportrefreshToken")
    } 
    try {
      const response = await submitRefreshToken(postData).unwrap();
  
      if (response) {
        console.log("response", response);
        localStorage.setItem("SupportAccessToken", response.access_token);
        localStorage.setItem("SupportrefreshToken", response.refresh_token);
      }
    } catch (err) {
      console.error("Failed to refresh token:", err);
    }
  };

  
  useEffect(() => {
    if (isErrorRefreshToken) {
      navigate("/");
      localStorage.clear();
    }
  }, [isErrorRefreshToken])


  useEffect(() => {
    const intervalId = setInterval(() => {
      handleSubmitRefreshToken();
    }, 10000); // 10 seconds

    return () => clearInterval(intervalId);
  }, [refreshToken, refreshtokenNew]);

  return (
    <>
      <button className="toggle-btn" onClick={toggleSidebar}>
        ☰
      </button>
      <Sidebar className={isSidebarOpen ? "Sidebar active" : "Sidebar"}>
        <button className="close-btn" onClick={closeSidebar}>
          ×
        </button>
        <img src={Logo} className="img" alt="Logo" />
        <Menu>
          <Link
            to="/dashboard"
            className={
              window.location.pathname === "/dashboard"
                ? "sub-menu-item-active"
                : ""
            }
          >
            {" "}
            <MenuItem className="pl-5">
              <img
                src={MediaGallery}
                alt="MediaGallery"
                className="menu-icon"
              />
              Ticket Management
            </MenuItem>
          </Link>
          {/* <SubMenu
            label="Ticket Management"
            icon={
              <img
                src={MediaGallery}
                alt="Community"
                className="menu-icon"
              />
            }
          >
            <Link to="/dashboard">
              <MenuItem
                className={
                  window.location.pathname === "/dashboard"
                    ? "sub-menu-item-active"
                    : ""
                }
              >
                Cafe Entrepreneur
              </MenuItem>
            </Link>
            <Link to="/food-ticket">
              <MenuItem
                className={
                  window.location.pathname === "/food-ticket"
                    ? "sub-menu-item-active"
                    : ""
                }
              >
                Food service
              </MenuItem>
            </Link>
            <Link to="/trade-ticket">
              <MenuItem
                className={
                  window.location.pathname === "/trade-ticket"
                    ? "sub-menu-item-active"
                    : ""
                }
              >
                Trades & Services
              </MenuItem>
            </Link>
          </SubMenu> */}
        </Menu>
      </Sidebar>
    </>
  );
}

export default SidebarComponent;
