import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import defaultplaceholder from "../Assets/Images/default-placeholder.svg";
import MUltiSelectAdd from "../Assets/Images/multiselct-Add.svg";
import GoogleImg from "../Assets/Images/Google.svg";
import Trustpilot from "../Assets/Images/Trustpilot_ Reviews & Ratings.svg";
import {
  useGetUserdetailsQuery,
  useFSeditBusinessDetailsMutation,
  useGetFSServiceCategoryQuery,
  useGetFSSpecialistDietryServicesQuery,
  useGetcefeaturesQuery,
  useGetcemenuhighlightQuery,
  useGetspecialistdietryServiceQuery,
  useFsEditAdditionalDetailsMutation,
  useFSEditEcosystemCriteriaMutation,
  useUserEditMutation,
  useGetStatesQuery
} from "../Services/UserAPI";

function CafeDetails({ id, type, userId }) {
  const { data, refetch } = useGetUserdetailsQuery({ id, type });
  const { data: fsDietryServiceData } = useGetFSSpecialistDietryServicesQuery();
  const { data: featureData } = useGetcefeaturesQuery();
  const { data: menuhighlightData } = useGetcemenuhighlightQuery();
  const { data: specialDietryService } = useGetspecialistdietryServiceQuery();


  const { data: statesData } = useGetStatesQuery();

  const [submitUserEditForm, { }] =
  useUserEditMutation();

  const [selectedLogo, setSelectedLogo] = useState(null);
  const [selectedMenu, setselectedMenu] = useState(null);
  const [selectedImage, setselectedImage] = useState(null);
  const [selectedCoverImage, setselectedCoverImage] = useState(null);
  const [selectedMinibio, setMinibio] = useState("")
  
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedMenuHighlights, setSelectedMenuHighlights] = useState([]);
  const [selectedDietaryServices, setSelectedDietaryServices] = useState([]);
  const [selectedAmbience, setSelectedAmbience] = useState([]);
  const [selectedTechStack, setSelectedTechStack] = useState([]);
  const [selectedFeatureseco, setSelectedFeatureseco] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedDietryService, setSelectedDietryService] = useState([]);
  const [selectedFSStates, setSelectedFsStates] = useState([]);
  const [selectedTSStates, setSelectedTSStates] = useState([]);
  const [selectedTradeServiceType, setSelectedTradeServiceType] = useState([]);
  const [selectedFsServiceCategory, setselectedFSServiceCategory] = useState(
    []
  );
  const [selectedFsSpecialDietryService, setelectedFsSpecialDietryService] =
    useState([]);
  const [selectedCEFeatureChange, setSelectedCEFeatureChange] = useState([]);
  useEffect(() => {
    if (data) {
      setselectedFSServiceCategory(
        data?.additional_detail?.service_categories?.map(
          (category) => category.id
        ) || []
      );
      setelectedFsSpecialDietryService(
        data?.additional_detail?.specialist_dietary_services?.map(
          (category) => category.id
        ) || []
      );
      setSelectedCEFeatureChange(
        data?.ecosystem_criteria?.ce_features?.map((category) => category.id) ||
          []
      );
      setSelectedDietryService(
        data?.ecosystem_criteria?.ce_specialist_dietary_services?.map(
          (category) => category.id
        ) || []
      );
      setMinibio(data?.additional_detail?.min_bio)
      const operationalStatus =
      data?.ecosystem_criteria?.ce_operational_status || [];

      const statusIds = operationalStatus.map((status) => status.id);

      const statusMapping = {
        planning: statusIds.includes(1), // ID 1 is for planning
        open: statusIds.includes(2), // ID 2 is for open
        exiting: statusIds.includes(3), // ID 3 is for exiting
      };
    

      setSelectedOperationalStatus(statusMapping);

    }
  }, [data]);

  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useFSeditBusinessDetailsMutation();

  const [submitEcosystemCriteria, {}] = useFSEditEcosystemCriteriaMutation();

  const [submitAdditionalData, {}] = useFsEditAdditionalDetailsMutation();

  const { data: serviceData } = useGetFSServiceCategoryQuery();

  // Handlers for checkbox changes
  const handleFeatureChange = (id) => {
    setSelectedFeatures((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };
  const handleChangeFsServiceCategory = (id) => {
    setselectedFSServiceCategory((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleChangeFsSpecialdietryService = (id) => {
    setelectedFsSpecialDietryService((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleChangeCeFeatures = (id) => {
    setSelectedCEFeatureChange((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleMenuHighlightChange = (id) => {
    setSelectedMenuHighlights((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((highlightId) => highlightId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDietaryServiceChange = (id) => {
    setSelectedDietaryServices((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleAmbienceChange = (id) => {
    setSelectedAmbience((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleTechStackChange = (id) => {
    setSelectedTechStack((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  // Other handlers
  const handleFeatureChangeEco = (id) => {
    setSelectedFeatureseco((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleProcductChange = (id) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDietryServiceChange = (id) => {
    setSelectedDietryService((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleFsStateChange = (id) => {
    setSelectedFsStates((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleTsStateChange = (id) => {
    setSelectedTSStates((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleChangeTradeServiceType = (id) => {
    setSelectedTradeServiceType((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  // Managing visibility of different sections
  const [isViewbusinessDetails, setisViewbusinessDetails] = useState(true);
  const handleClickEDitBusinessDetails = (e) => {
    e.preventDefault();
    setisViewbusinessDetails(false);
  };

  const handleClickCancelBusinessDetails = (e) => {
    e.preventDefault();
    setisViewbusinessDetails(true);
  };

  const [isViewonlinePreference, setisViewOnlinePreference] = useState(true);
  const handleClickEDitOnlinePreference = (e) => {
    e.preventDefault();
    setisViewOnlinePreference(false);
  };

  const handleClickCancelOnlinePreference = (e) => {
    e.preventDefault();
    setisViewOnlinePreference(true);
  };

  const [isViewDetails, setisViewDetails] = useState(true);
  const handleClickEDitDetails = (e) => {
    e.preventDefault();
    setisViewDetails(false);
  };

  const handleClickCancelDetails = (e) => {
    e.preventDefault();
    setisViewDetails(true);
  };

  const [isViewAdditionalDetails, setisViewAdditionalDetails] = useState(true);
  const handleClickEDiAdditionalDetails = (e) => {
    e.preventDefault();
    setisViewAdditionalDetails(false);
  };

  const handleClickCancelAdditionalDetails = (e) => {
    e.preventDefault();
    setisViewAdditionalDetails(true);
  };

  const [isViewEcosystemCriteria, setisViewEcosystemCriteria] = useState(true);
  const handleClickEcosystemCriteria = (e) => {
    e.preventDefault();
    setisViewEcosystemCriteria(false);
  };

  const handleClickCancelEcosystemCriteria = (e) => {
    e.preventDefault();
    setisViewEcosystemCriteria(true);
  };

  const [isViewTradesandService, setisViewTradesandService] = useState(true);
  const handleClickEditTradesandService = (e) => {
    e.preventDefault();
    setisViewTradesandService(false);
  };

  const [isViewAccountHolderDetails, setiisViewAccountHolderDetails] =
    useState(true);
  const handleClickEditAccountHolderDetails = (e) => {
    e.preventDefault();
    setiisViewAccountHolderDetails(false);
  };

  const handleClickCancelAccountHolderDetails = (e) => {
    e.preventDefault();
    setiisViewAccountHolderDetails(true);
  };

  const [accountHolderForm, setAccountHolderForm] = useState({
    first_name: "",
    last_name:  "",
    email:  "",
    contact_number:  "",
    state: "",
    post_code:  "",
  });

  const [businessDetailsForm, setBusinessDetailsForm] = useState({
    business_name: "",
    abn: "",
    email: "",
    contact_number: "",
    location: "",
    post_code: "",
  });

  
  useEffect(() => {
    if (data?.user) {
      setAccountHolderForm({
        first_name: data?.user?.first_name || "",
    last_name: data?.user?.last_name || "",
    email: data?.user?.email || "",
    contact_number: data?.user?.contact_number || "",
    state: data?.user?.state_id || "",
    post_code: data?.user?.post_code || "",
      });
    }
  }, [data?.user]);

  // Update state when data becomes available
  useEffect(() => {
    if (data?.branch) {
      setBusinessDetailsForm({
        business_name: data.branch.business_name || "",
        abn: data.branch.abn || "",
        email: data.branch.email || "",
        contact_number: data.branch.contact_number || "",
        location: data.branch.location || "",
        post_code: data.branch.post_code || "",
      });
    }
  }, [data?.branch]);

  // Function to handle account holder details submit
  const handleSubmitAccountHolderDetails = (e) => {
    e.preventDefault();
    // Perform validation or API call
    console.log("Submitting Account Holder Details", businessDetailsForm);
    // API call logic
  };

  const handleSubmitBusinessDetails = async (e) => {
    e.preventDefault();
    console.log("businessDetailsForm", businessDetailsForm);
    try {
      const response = await submitForm({
        businessDetailsForm,
        id,
        userId,
      }).unwrap();
      alert("Form submitted succesfully");
      refetch();
    } catch (err) {
      console.error("Failed to submit form:", err);
      alert("Failed to submit form");
    }
  };

  const handleSubmitAdditionalData = async (e) => {
    e.preventDefault();

    const postData = new FormData();

    if (selectedLogo) postData.append("logo", selectedLogo);
    if (selectedMenu) postData.append("menu", selectedMenu);
    
    if (selectedImage) {
      selectedImage.forEach((item) => postData.append("images", item));
    }

    selectedFsServiceCategory.forEach((item) =>
      postData.append("service_categories", item)
    );
    // selectedMenuHighlights.forEach((item) =>
    //   postData.append("product_types", item)
    // );
    selectedFsSpecialDietryService.forEach((item) =>
      postData.append("specialist_dietary_services", item)
    );

    if(selectedMinibio){
      postData.append("min_bio", selectedMinibio)
    }
    console.log("data?.additional_detail", data?.additional_detail);

    try {
      const response = await submitAdditionalData({
        postData,
        branchid: id,
      userId,
      id:data?.additional_detail?.id,
      }).unwrap();
      if (response) {
        alert("Form submitted succesfully");
        refetch();
      }
    } catch (err) {
      alert("Failed to submit form");
    }
  };

  const handleSubmitEcosystemCritera = async (e) => {
    alert();
    e.preventDefault();
    let postData = {
      ce_features: selectedCEFeatureChange,
      ce_specialist_dietary_services: selectedDietryService,
  
    };

    if (operationalStatus.length > 0) {
      postData.ce_operational_status = operationalStatus;
    }
    try {
      const response = await submitEcosystemCriteria({
        postData,
        branchid: id,
        userId,
        id: data?.ecosystem_criteria?.id,
      }).unwrap();
      if (response) {
        alert("form submitted succesfully");
        refetch();
      }
    } catch (err) {}
  };

  const [onlinePreferenceForm, setonlinePreferenceForm] = useState({
    weburl: "",
    instagram: "",
    facebook: "",
    linkedin: "",
    twitter: "",
    tiktok: "",
    youtube: "",
  });

  // Update state when data becomes available
  useEffect(() => {
    if (data?.branch) {
      setonlinePreferenceForm({
        weburl: data.branch.weburl || "",
        instagram: data.branch.instagram || "",
        facebook: data.branch.facebook || "",
        linkedin: data.branch.linkedin || "",
        twitter: data.branch.twitter || "",
        youtube: data.branch.youtube || "",
        tiktok: data.branch.tiktok || "",
      });
    }
  }, [data?.branch]);


  const handleSubmitAccountDetails = async (e) => {
    e.preventDefault();
    console.log("accountHolderForm", accountHolderForm);

    const transformedPayload = {
      contact_number: accountHolderForm.contact_number,
      profile: {
        first_name: accountHolderForm.first_name,
        last_name: accountHolderForm.last_name,
        post_code: accountHolderForm.post_code,
        state: accountHolderForm.state,
      },
    }
    try {
      const response = await submitUserEditForm({
        transformedPayload,
        userId,
      }).unwrap();
      alert("Form submitted succesfully");
      refetch();
    } catch (err) {
      alert("Failed to submit form");
    }
  };

  const handleSubmitOlinepreferenceDetails = async (e) => {
    e.preventDefault();
    try {
      const response = await submitForm({
        businessDetailsForm: onlinePreferenceForm,
        id,
        userId,
      }).unwrap();
      alert("Form submitted succesfully");
      refetch();
    } catch (err) {
      console.error("Failed to submit form:", err);
      alert("Enter Valid url");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBusinessDetailsForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleChangeUserDetails = (e) => {
    const { name, value } = e.target;
    setAccountHolderForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleInputChangeOnlinepreference = (e) => {
    const { name, value } = e.target;
    setonlinePreferenceForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleFileValidation = (file, allowedTypes) => {
    const fileType = file?.type?.split("/")[1].toLowerCase();
    return allowedTypes.includes(fileType);
  };

  const handlelogoChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 300 * 1024) {
      alert("File size exceeds 300 KB. Please select a smaller file.");
      setSelectedLogo(""); // Clear the selected file
      return;
    }

    
    setSelectedLogo(file);
    if (handleFileValidation(file, ["jpg", "png", "svg", "jpeg"])) {
      setSelectedLogo(file);
    } else {
      alert("Invalid file type. Only .jpg, .png, and .svg files are allowed.");
      setSelectedLogo("");
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    if (files.length < 4) {
      alert("Please select at least 4 images.");
      return;
    }

    if (files.length > 10) {
      alert("You can only upload a maximum of 10 images.");
      return;
    }

    // Validate file types
    const validFiles = files.filter((file) =>
      ["image/jpeg", "image/jpg", "image/png", "image/svg+xml"].includes(
        file.type
      )
    );

    if (validFiles.length !== files.length) {
      alert("Only .jpg, .png, and .svg files are allowed.");
      return;
    }

    // Update state with valid files
    setselectedImage(validFiles);
  };

  const handleChangeMiniBio = (e)=>{
    setMinibio(e.target.value);
  }

  const [selectedOperationalStatus, setSelectedOperationalStatus] = useState({});
  const [operationalStatus, setOperationalStatus] = useState([]);

  const handleChangeStatus = (e) => {
    const { value } = e.target;

    setSelectedOperationalStatus((prevState) => {
      const updatedOptions = {
        ...prevState,

        [value]: !prevState[value],
      };

      const updatedStatus = [];
      if (updatedOptions.planning) updatedStatus.push(1);
      if (updatedOptions.open) updatedStatus.push(2);
      if (updatedOptions.exiting) updatedStatus.push(3);

      setOperationalStatus(updatedStatus);

      return updatedOptions;
    });
  };

  return (
    <div className="user-details-tab-content">
      <form>
        <Row className="mt-20">
          <Col sm={12} md={6} lg={6}>
            <h3>Account holder details</h3>
          </Col>
        </Row>

        <hr />
        <div>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  First name<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={accountHolderForm?.first_name}
                  name="first_name"
                  placeholder="First name"
                  disabled={isViewAccountHolderDetails}
                  onChange={handleChangeUserDetails}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Last name<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={accountHolderForm?.last_name}
                  name="last_name"
                  placeholder="Last name"
                  disabled={isViewAccountHolderDetails}
                  onChange={handleChangeUserDetails}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Email<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={accountHolderForm?.email}
                  name="email"
                  placeholder="Email"
                  disabled
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Contact number<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={accountHolderForm?.contact_number}
                  name="contact_number"
                  placeholder="Contact number"
                  disabled={isViewAccountHolderDetails}
                  onChange={handleChangeUserDetails}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  State<span className="red-star">*</span>
                </label>
                <br />
                <select disabled={isViewAccountHolderDetails} name="state" value={accountHolderForm?.state}  onChange={handleChangeUserDetails}>
                  {statesData?.map((state) => (
                  <option key={state.id} value={state.id}>{state.name}</option>
                ))}
                </select>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Post code<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={accountHolderForm?.post_code}
                  name="post_code"
                  placeholder="Post code"
                  disabled={isViewAccountHolderDetails}
                  onChange={handleChangeUserDetails}
                />
              </div>
            </Col>
          </Row>
        </div>
        <Row className="mt-20">
          {isViewAccountHolderDetails ? (
            <Col sm={12} md={12} lg={12} className="text-end">
              <button
                className="edit-btn btn-primary"
                onClick={handleClickEditAccountHolderDetails}
                type="button"
              >
                Edit
              </button>
            </Col>
          ) : (
            <Col sm={12} md={12} lg={12} className="text-end">
              <button
                className="btn-outline-edit"
                onClick={handleClickCancelAccountHolderDetails}
                type="button"
              >
                Cancel
              </button>
              <button
                className="edit-btn btn-primary"
                onClick={handleSubmitAccountDetails}
                type="button"
              >
                Submit
              </button>
            </Col>
          )}
        </Row>
        <Row className="mt-20">
          <Col sm={12} md={6} lg={6}>
            <h3 className="mt-20">Business details</h3>
          </Col>
        </Row>

        <hr />
        <div>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Business name<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={businessDetailsForm.business_name}
                  name="business_name"
                  placeholder="Business name"
                  disabled={isViewbusinessDetails}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>ABN</label>
                <br />
                <input
                  type="text"
                  value={businessDetailsForm.abn}
                  name="abn"
                  placeholder="ABN"
                  disabled={isViewbusinessDetails}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Email<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={businessDetailsForm.email}
                  name="email"
                  placeholder="Email"
                  disabled={isViewbusinessDetails}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Contact number<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={businessDetailsForm.contact_number}
                  name="contact_number"
                  placeholder="Contact number"
                  disabled={isViewbusinessDetails}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Location<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={businessDetailsForm.location}
                  name="location"
                  placeholder="Location"
                  disabled={isViewbusinessDetails}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Post code<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={businessDetailsForm.post_code}
                  name="post_code"
                  placeholder="Post code"
                  disabled={isViewbusinessDetails}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
          </Row>
        </div>

        <Row className="mt-20">
          {isViewbusinessDetails ? (
            <Col sm={12} md={12} lg={12} className="text-end">
              <button
                className="edit-btn btn-primary"
                onClick={handleClickEDitBusinessDetails}
                type="button"
              >
                Edit
              </button>
            </Col>
          ) : (
            <Col sm={12} md={12} lg={12} className="text-end">
              <button
                className="btn-outline-edit"
                onClick={handleClickCancelBusinessDetails}
                type="button"
              >
                Cancel
              </button>
              <button
                className="edit-btn btn-primary"
                type="button"
                onClick={handleSubmitBusinessDetails}
              >
                Submit
              </button>
            </Col>
          )}
        </Row>

        <Row className="mt-20">
          <Col sm={12} md={6} lg={6}>
            <h3 className="mt-20">Online Presence</h3>
          </Col>
        </Row>

        <hr />
        <div>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>Web URL</label>
                <br />
                <input
                  type="url"
                  value={onlinePreferenceForm.weburl}
                  name="weburl"
                  placeholder="Web url"
                  disabled={isViewonlinePreference}
                  onChange={handleInputChangeOnlinepreference}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>Instagram</label>
                <br />
                <input
                  type="url"
                  value={onlinePreferenceForm?.instagram}
                  name="instagram"
                  placeholder="Instagram"
                  disabled={isViewonlinePreference}
                  onChange={handleInputChangeOnlinepreference}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>Facebook</label>
                <br />
                <input
                  type="url"
                  value={onlinePreferenceForm?.facebook}
                  name="facebook"
                  placeholder="Facebook"
                  disabled={isViewonlinePreference}
                  onChange={handleInputChangeOnlinepreference}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>LinkedIn</label>
                <br />
                <input
                  type="url"
                  value={onlinePreferenceForm?.linkedin}
                  name="linkedin"
                  placeholder="linkedin"
                  disabled={isViewonlinePreference}
                  onChange={handleInputChangeOnlinepreference}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>Twitter</label>
                <br />
                <input
                  type="url"
                  value={onlinePreferenceForm?.twitter}
                  name="twitter"
                  placeholder="Twitter"
                  disabled={isViewonlinePreference}
                  onChange={handleInputChangeOnlinepreference}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>TikTok</label>
                <br />
                <input
                  type="url"
                  value={onlinePreferenceForm?.tiktok}
                  name="tiktok"
                  placeholder="TikTok"
                  disabled={isViewonlinePreference}
                  onChange={handleInputChangeOnlinepreference}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>YouTube</label>
                <br />
                <input
                  type="url"
                  value={onlinePreferenceForm?.youtube}
                  name="youtube"
                  placeholder="YouTube"
                  disabled={isViewonlinePreference}
                  onChange={handleInputChangeOnlinepreference}
                />
              </div>
            </Col>
          </Row>
        </div>

        <Row className="mt-20">
          {isViewonlinePreference ? (
            <Col sm={12} md={12} lg={12} className="text-end">
              <button
                className="edit-btn btn-primary"
                onClick={handleClickEDitOnlinePreference}
                type="button"
              >
                Edit
              </button>
            </Col>
          ) : (
            <Col sm={12} md={12} lg={12} className="text-end">
              <button
                className="btn-outline-edit"
                onClick={handleClickCancelOnlinePreference}
                type="button"
              >
                Cancel
              </button>
              <button
                className="edit-btn btn-primary"
                type="button"
                onClick={handleSubmitOlinepreferenceDetails}
              >
                Submit
              </button>
            </Col>
          )}
        </Row>

        <Row className="mt-20">
          <Col sm={12} md={6} lg={6}>
            <h3 className="mt-20">Details</h3>
          </Col>
        </Row>

        <hr />
        <div>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>Logo</label>
                <br />
                <img
                  src={data?.additional_detail?.logo}
                  alt="defaultplaceholder"
                  className="uploaded-images"
                />
              </div>
              <input
                  type="file"
                  className="file-upload-input"
                  onChange={handlelogoChange}
                  disabled={isViewDetails}
                />
            </Col>

            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>Images</label>
                <br />

                {data?.additional_detail?.images.map((img) => (
                  <img
                    src={img.image || defaultplaceholder}
                    alt="defaultplaceholder"
                    className="uploaded-images"
                  />
                ))}
              
              </div>
              <input
                  type="file"
                  className="file-upload-input"
                  onChange={handleImageChange}
                  disabled={isViewDetails}
                  multiple
                />
            </Col>
            <Col sm={12} md={12} lg={12}>
              <div className="form-control">
                <label>Mini Bio</label>
                <br />
                <textarea
                  className="text-area-min-bio"
                  disabled={isViewDetails}
                  value={selectedMinibio}
                  onChange={handleChangeMiniBio}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <Row className="mt-20">
            {isViewDetails ? (
              <Col sm={12} md={12} lg={12} className="text-end">
                <button
                  className="edit-btn btn-primary"
                  onClick={handleClickEDitDetails}
                  type="button"
                >
                  Edit
                </button>
              </Col>
            ) : (
              <Col sm={12} md={12} lg={12} className="text-end">
                <button
                  className="btn-outline-edit"
                  onClick={handleClickCancelDetails}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="edit-btn btn-primary"
                  type="button"
                  onClick={handleSubmitAdditionalData}
                >
                  Submit
                </button>
              </Col>
            )}
          </Row>

          <Row>
            <Col sm={12} md={6} lg={6}>
              <h3 className="mt-20">Additional Details</h3>
            </Col>
          </Row>

          <hr />
          <Row>
            <Col xs={12} sm={12} lg={6} md={6} className="pdr-40 mrt-20">
              <div className="form-control">
                <label className="choose-label">
                  Service Category
                  <span className="red-star">*</span>
                </label>
                <br />
                <div className="multi-select-wrapper">
                  {/* {featuresData?.map((option) => ( */}
                  {serviceData?.map((features) => (
                    <div key={features?.id} className="multi-select-item">
                      <div
                        className={`multi-select-container ${
                          selectedFsServiceCategory.includes(features?.id)
                            ? "selected"
                            : ""
                        }`}
                        onClick={() =>
                          !isViewAdditionalDetails &&
                          handleChangeFsServiceCategory(features?.id)
                        }
                      >
                        <input
                          type="checkbox"
                          id={`option${features?.id}`}
                          name="services"
                          value={features?.id}
                          checked={selectedFsServiceCategory.includes(
                            features?.id
                          )}
                          onChange={() =>
                            handleChangeFsServiceCategory(features?.id)
                          }
                          style={{ display: "none" }}
                          disabled={isViewAdditionalDetails}
                        />
                        <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                        <label
                          htmlFor={`option${features.id}`}
                          title={features.name} // Show full name on hover
                        >
                          {features.name.length > 20
                            ? `${features.name.substring(0, 20)}...`
                            : features.name}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="see-more">See more..</div> */}
            </Col>

            <Col xs={12} sm={12} lg={6} md={6} className="pdr-40 mrt-20">
              <div className="form-control">
                <label className="choose-label">
                  Specialist Dietary Services
                  <span className="red-star">*</span>
                </label>
                <br />
                <div className="multi-select-wrapper">
                  {/* {featuresData?.map((option) => ( */}
                  {fsDietryServiceData?.map((features) => (
                    <div key={features?.id} className="multi-select-item">
                      <div
                        className={`multi-select-container ${
                          selectedFsSpecialDietryService.includes(features?.id)
                            ? "selected"
                            : ""
                        }`}
                        onClick={() =>
                          !isViewAdditionalDetails &&
                          handleChangeFsSpecialdietryService(features?.id)
                        }
                      >
                        <input
                          type="checkbox"
                          id={`option${features?.id}`}
                          name="services"
                          value={features?.id}
                          checked={selectedFsSpecialDietryService.includes(
                            features?.id
                          )}
                          onChange={() =>
                            handleChangeFsSpecialdietryService(features?.id)
                          }
                          style={{ display: "none" }}
                          disabled={isViewAdditionalDetails}
                        />
                        <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                        <label
                          htmlFor={`option${features.id}`}
                          title={features.name} // Show full name on hover
                        >
                          {features.name.length > 20
                            ? `${features.name.substring(0, 20)}...`
                            : features.name}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="see-more">See more..</div> */}
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <h3 className="mt-20">Additional Details</h3>
            </Col>
          </Row>

          <hr />
          <Row className="news-reviews">
            <Col md={6} lg={6} sm={12}>
              <h5>Upcoming events</h5>
              <hr />
              {data?.additional_detail?.events?.map((event, index) => (
                <div
                  key={event.id}
                  className={index % 2 === 0 ? "div1" : "div2"}
                >
                  <p>
                    <a
                      href={event.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {event.title}
                    </a>
                  </p>
                </div>
              ))}
            </Col>

            <Col md={6} lg={6} sm={12}>
              <h5>Online reviews</h5>
              <hr />
              {data?.additional_detail?.reviews?.map((review, index) => (
                <div
                  key={review.id}
                  className={index % 2 === 0 ? "div1" : "div2"}
                >
                  <p>
                    <img
                      src={
                        review.platform_name === "Google Reviews"
                          ? GoogleImg
                          : Trustpilot
                      }
                      alt={review.platform_name}
                    />
                    <a
                      href={review.review_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {review.platform_name}
                    </a>
                  </p>
                </div>
              ))}
            </Col>
          </Row>
        </div>
        <div>
          <Row className="mt-20">
            {isViewAdditionalDetails ? (
              <Col sm={12} md={12} lg={12} className="text-end">
                <button
                  className="edit-btn btn-primary"
                  onClick={handleClickEDiAdditionalDetails}
                  type="button"
                >
                  Edit
                </button>
              </Col>
            ) : (
              <Col sm={12} md={12} lg={12} className="text-end">
                <button
                  className="btn-outline-edit"
                  onClick={handleClickCancelAdditionalDetails}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="edit-btn btn-primary"
                  type="button"
                  onClick={handleSubmitAdditionalData}
                >
                  Submit
                </button>
              </Col>
            )}
          </Row>

          <Row className="mt-20">
            <Col sm={12} md={6} lg={6}>
              <h3 className="mt-20">Ecosystem Criteria</h3>
            </Col>
          </Row>

          <hr />
          <Row>
            <h5 className="mb-20 mt-20">Cafe</h5>
            <Col xs={12} sm={12} lg={6} md={6} className="pdr-40">
              <div className="form-control">
                <label className="choose-label">
                  Preferred operational status
                  <span className="red-star">*</span>
                </label>
                <br />
                <div className="multi-select-wrapper">
                  {/* {data?.ecosystem_criteria?.ce_operational_status?.map(
                    (option) => (
                      <div key={option.id} className="multi-select-item">
                        <div
                          className={`multi-select-container ${
                            selectedFeatureseco.includes(option.id)
                              ? "selected"
                              : ""
                          }`}
                          onClick={() =>
                            !isViewEcosystemCriteria &&
                            handleFeatureChangeEco(option.id)
                          }
                        >
                          <input
                            type="checkbox"
                            id={`option${option.id}`}
                            name="services"
                            value={option.id}
                            checked={selectedFeatures.includes(option.id)}
                            onChange={() => handleFeatureChangeEco(option.id)}
                            style={{ display: "none" }}
                            disable={isViewEcosystemCriteria}
                          />
                          <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                          <label
                            htmlFor={`option${option.id}`}
                            title={option.name} // Show full name on hover
                          >
                            {option.name.length > 10
                              ? `${option.name.substring(0, 10)}...`
                              : option.name}
                          </label>
                        </div>
                      </div>
                    )
                  )} */}

<div className="">
                  <div className="checkbox-group">
                    <label className="deleivery-yes">
                      <input
                        type="checkbox"
                        id="planning"
                        name="confirmation_status"
                        value="planning"
                        onChange={handleChangeStatus}
                        checked={selectedOperationalStatus.planning}
                      />
                      <span className="check-delivery-text">Planning to open</span>
                    </label>
                    <label className="deleivery-yes">
                      <input
                        type="checkbox"
                        id="open"
                        name="confirmation_status"
                        value="open"
                        onChange={handleChangeStatus}
                        checked={selectedOperationalStatus.open}
                      />
                      <span className="check-delivery-text">Open for business</span>
                    </label>
                    <label className="deleivery-yes">
                      <input
                        type="checkbox"
                        id="exiting"
                        name="confirmation_status"
                        value="exiting"
                        onChange={handleChangeStatus}
                        checked={selectedOperationalStatus.exiting}
                      />
                      <span className="check-delivery-text">Looking to exit</span>
                    </label>
                  </div>
                </div>

                </div>
              </div>
              {/* <div className="see-more">See more..</div> */}
            </Col>
            <Col xs={12} sm={12} lg={6} md={6} className="pdr-40">
              <div className="form-control">
                <label className="choose-label">
                  Features
                  <span className="red-star">*</span>
                </label>
                <br />
                <div className="multi-select-wrapper">
                  {featureData?.map((option) => (
                    <div key={option.id} className="multi-select-item">
                      <div
                        className={`multi-select-container ${
                          selectedCEFeatureChange.includes(option.id)
                            ? "selected"
                            : ""
                        }`}
                        onClick={() =>
                          !isViewEcosystemCriteria &&
                          handleChangeCeFeatures(option.id)
                        }
                      >
                        <input
                          type="checkbox"
                          id={`option${option.id}`}
                          name="services"
                          value={option.id}
                          checked={selectedCEFeatureChange.includes(option.id)}
                          onChange={() => handleChangeCeFeatures(option.id)}
                          style={{ display: "none" }}
                          disabled={isViewEcosystemCriteria}
                        />
                        <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                        <label htmlFor={`option${option.id}`}>
                          {option.name.length > 10
                            ? `${option.name.substring(0, 10)}...`
                            : option.name}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="see-more">See more..</div> */}
            </Col>
            <Col xs={12} sm={12} lg={6} md={6} className="pdr-40">
              <div className="form-control">
                <label className="choose-label">
                  Special Dietary Services
                  <span className="red-star">*</span>
                </label>
                <br />
                <div className="multi-select-wrapper">
                  {specialDietryService?.map((option) => (
                    <div key={option.id} className="multi-select-item">
                      <div
                        className={`multi-select-container ${
                          selectedDietryService.includes(option.id)
                            ? "selected"
                            : ""
                        }`}
                        onClick={() =>
                          !isViewEcosystemCriteria &&
                          handleDietryServiceChange(option.id)
                        }
                      >
                        <input
                          type="checkbox"
                          id={`option${option.id}`}
                          name="services"
                          value={option.id}
                          checked={selectedDietryService.includes(option.id)}
                          onChange={() => handleDietryServiceChange(option.id)}
                          style={{ display: "none" }}
                          disabled={isViewEcosystemCriteria}
                        />
                        <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                        <label htmlFor={`option${option.id}`}>
                          {option.name.length > 10
                            ? `${option.name.substring(0, 10)}...`
                            : option.name}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="see-more">See more..</div> */}
            </Col>
          </Row>
        </div>
        <Row className="mt-20">
          {isViewEcosystemCriteria ? (
            <Col sm={12} md={12} lg={12} className="text-end">
              <button
                className="edit-btn btn-primary"
                onClick={handleClickEcosystemCriteria}
                type="button"
              >
                Edit
              </button>
            </Col>
          ) : (
            <Col sm={12} md={12} lg={12} className="text-end">
              <button
                className="btn-outline-edit"
                onClick={handleClickCancelEcosystemCriteria}
                type="button"
              >
                Cancel
              </button>
              <button
                className="edit-btn btn-primary"
                type="button"
                onClick={handleSubmitEcosystemCritera}
              >
                Submit
              </button>
            </Col>
          )}
        </Row>
      </form>
    </div>
  );
}

export default CafeDetails;
