import React from "react";
import SearchImg from "../Assets/Images/search-img.svg";
import FilterImg from "../Assets/Images/Filters lines.svg";
import { Link } from "react-router-dom";
import { useGetAllTicketsQuery } from "../Services/SupportAPI";

function MediaGallery() {
  const { data, isLoading } = useGetAllTicketsQuery();
  const tickets = data?.tickets; // Accessing the tickets array from the response
  if (isLoading) {
    return <p>Loading...</p>;
  }
  return (
    <div>
      <h3>Ticket Management</h3>
      <div className="table-wrapper-div">
        <div className="search-div">
          <div className="form-control-input">
            {" "}
            <input type="text" placeholder="Search" />
            <img src={SearchImg} alt={SearchImg} className="search-img" />
          </div>

          <button className="filter-btn">
            <img src={FilterImg} alt={FilterImg} /> Filters
          </button>
        </div>

        <div className="table-div">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Ticket no</th>
                <th scope="col">Account holder</th>
                <th scope="col">Sub category</th>
                <th scope="col">Added on</th>
                <th scope="col">Status</th>
                <th scope="col">Last update</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
            {tickets?.map((ticket) => (
                <tr key={ticket.id}>
                  <td>{ticket.ticket_code_ID}</td>
                  <td>
                    <span className="ylw-text">OR</span> {ticket.post_user_name}
                  </td>
                  <td>{ticket.subcategory_name}</td>
                  <td>{new Date(ticket.created_at).toLocaleDateString()}</td>
                  <td className="d-flex">
                    <button className="new-status">
                      <p className="violet-circle"></p>
                      <span>{ticket.status}</span>
                    </button>
                  </td>
                  <td>{new Date(ticket.updated_at).toLocaleDateString()}</td>
                  <td className="text-end">
                    <Link to={`/trade-view-ticket/${ticket.id}`}>
                      <button className="btn-view">View</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default MediaGallery;
