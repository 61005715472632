import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_CORE_SERVER;

export const userapi = createApi({
  reducerPath: 'userapi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      const token = localStorage.getItem('SupportAccessToken');
      console.log('Access Token:', token); 
      
      const noAuthEndpoints = ['getStates'];
      if (token && !noAuthEndpoints.includes(endpoint)) {
        headers.set('authorization', `Bearer ${token}`);  // Fix here with backticks
      }
      
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUserdetails: builder.query({
      query: ({id , type}) => `external/${type}-user/branch/${id}/detail/`,  
    }),
    getMediagalleryts: builder.query({
      query: () => `external/ts-media-gallery/`,
    }),
    getMediagalleryfs: builder.query({
      query: () => `external/fs-media-gallery/`,
    }),

    editBusinessDetails: builder.mutation({
      query: ({businessDetailsForm, id,userId}) => ({
        url: `external/ce-branch/${userId}/${id}/edit/`,
        method: 'PATCH',
        body: businessDetailsForm,
      }),
    }),
    FSeditBusinessDetails: builder.mutation({
      query: ({businessDetailsForm, id, userId}) => ({
        url: `external/fs-branch/${userId}/${id}/edit/`,
        method: 'PATCH',
        body: businessDetailsForm,
      }),
    }),
    
    TSeditBusinessDetails: builder.mutation({
      query: ({businessDetailsForm, id, userId}) => ({
        url: `external/ts-branch/${userId}/${id}/edit/`,
        method: 'PATCH',
        body: businessDetailsForm,
      }),
    }),
getcefeatures : builder.query({
  query: () => `external/ce-features/`,
}),
getspecialistdietryService: builder.query({
  query: () => `external/ce-specialist-dietary-services/`,
}),
getceAmbience: builder.query({
  query: () => `external/ce-ambiences/`,
}),
getceTechStack: builder.query({
  query: () => `external/ce-tech-stacks/`,
}),
getcemenuhighlight : builder.query({
  query: () => `external/ce-menu-highlights/`,
}),
getFSServiceCategory: builder.query({
  query: () => `external/fs-service-categories/`,
}),
getFSSpecialistDietryServices: builder.query({
  query: () => `external/fs-specialist-dietary-services/`,
}),

getTSServiceCategory: builder.query({
  query: () => `external/ts-service-categories/`,
}),

ceEditAdditionalDetails :builder.mutation({
  query: ({postData, branchid, userId, id}) => ({
    url: `external/ce-additional_detail/${userId}/${branchid}/${id}/edit/`,
    method: 'PATCH',
    body: postData,
  }),
}),

tsEditAdditionalDetails :builder.mutation({
  query: ({postData, branchid, userId, id}) => ({
    url: `external/ts-additional_detail/${userId}/${branchid}/${id}/edit/`,
    method: 'PATCH',
    body: postData,
  }),
}),

fsEditAdditionalDetails :builder.mutation({
  query: ({postData, branchid, userId, id}) => ({
    url: `external/fs-additional_detail/${userId}/${branchid}/${id}/edit/`,
    method: 'PATCH',
    body: postData,
  }),
}),

ceEditEcosystemCriteria:builder.mutation({
  query: ({postData, branchid, userId ,id}) => ({
    url: `external/ce-eco-system-criteria/${userId}/${branchid}/${id}/edit/`,
    method: 'PATCH',
    body: postData,
  }),
}),

TsEditEcosystemCriteria:builder.mutation({
  query: ({postData, branchid, userId ,id}) => ({
    url: `external/ts-eco-system-criteria/${userId}/${branchid}/${id}/edit/`,
    method: 'PATCH',
    body: postData,
  }),
}),

fSEditEcosystemCriteria:builder.mutation({
  query: ({postData, branchid, userId ,id}) => ({
    url: `external/fs-eco-system-criteria/${userId}/${branchid}/${id}/edit/`,
    method: 'PATCH',
    body: postData,
  }),
}),

ceUserEdit:builder.mutation({
  query: ({transformedPayload, userId}) => ({
    url: `external/user/${userId}/edit/`,
    method: 'PATCH',
    body: transformedPayload,
  }),
}),

UserEdit:builder.mutation({
  query: ({transformedPayload, userId}) => ({
    url: `external/user/${userId}/edit/`,
    method: 'PATCH',
    body: transformedPayload,
  }),
}),

getStates:builder.query({
  query: () => "/common/states/",
}),
userProfileUpdate:builder.mutation({
  query: ({postData,userId}) => ({
    url: `external/user-profile-picture-update/${userId}/`,
    method: 'PATCH',
    body: postData,
  }),
}),

accountChangePassword: builder.mutation({
  query: ({postdata, userId}) => ({
    url: `external/user/${userId}/password-change/`,
    method: 'PATCH',
    body: postdata,
  }),
}),

accountChangeProfile: builder.mutation({
  query: ({formData,userId}) => ({
    url: `external/user-profile-picture-update/${userId}/`,
    method: 'PATCH',
    body: formData,
  }),
}),

accountDelete :builder.mutation({
  query: (userId) => ({
    url: `external/user/${userId}/delete/with-subscription-cancel/`,
    method: 'DELETE',
  }),
}),

getBranchList:builder.query({
  query: () => `cafe/branches/`,
}),


branchDelete :builder.mutation({
  query: (id) => ({
    url: `stripe/ce-branch/${id}/delete/with-subscription-cancel/`,
    method: 'DELETE',
  }),
}),

emailNotificationStatus: builder.mutation({
  query: ({Postata}) => ({
    url: `cafe/email-notification-status/get/edit/`,
    method: 'PATCH',
    body: Postata,
  }),
}),


getEmailNotificationStatus: builder.query({
  query: () => `cafe/email-notification-status/get/edit/`,
}),

ResetPassword: builder.mutation({
  query: (Postata) => ({
    url: `auth/password-reset/`,
    method: 'POST',
    body: Postata,
  }),
}),

getAccountHolderData: builder.query({
  query: (id) => `external/user-detail/${id}/`,
}),

NotificationMarkAllRead :builder.mutation({
  query: () => ({
    url: `external/custom-support-admin/portal-notifications/mark-all-read/`,
    method: 'POST',
  }),
}),

NotificationMarkReadTechnical:builder.mutation({
  query: () => ({
    url: `external/custom-technical-support-admin/portal-notifications/mark-all-read/`,
    method: 'POST',
  }),
}),

NotificationMarkReadWebsite:builder.mutation({
  query: () => ({
    url: `external/custom-website-support-admin/portal-notifications/mark-all-read/`,
    method: 'POST',
  }),
}),
NotificationMarkReadDirectory:builder.mutation({
  query: () => ({
    url: `external/custom-directory-support-admin/portal-notifications/mark-all-read/`,
    method: 'POST',
  }),
}),
NotificationMarkReadEcosystem:builder.mutation({
  query: () => ({
    url: `external/custom-ecosystem-support-admin/portal-notifications/mark-all-read/`,
    method: 'POST',
  }),
}),
NotificationMarkReadBusinessMaterial:builder.mutation({
  query: () => ({
    url: `external/custom-business-material-support-admin/portal-notifications/mark-all-read/`,
    method: 'POST',
  }),
}),

getMediaGallerybyUserIdfs: builder.query({
  query: (id) => `/external/fs-media-gallery/user/${id}/`,
}),
getMediaGallerybyUserIdts: builder.query({
  query: (id) => `/external/ts-media-gallery/user/${id}/`,
}),


  }),
});

export const {
  useGetUserdetailsQuery,
  useGetMediagalleryfsQuery,
  useGetMediagallerytsQuery,
  useEditBusinessDetailsMutation,
  useFSeditBusinessDetailsMutation,
  useTSeditBusinessDetailsMutation,
  useGetFSServiceCategoryQuery,
  useGetFSSpecialistDietryServicesQuery,
  useGetTSServiceCategoryQuery,
  useGetceAmbienceQuery,
  useGetceTechStackQuery,
  useGetcefeaturesQuery,
  useGetspecialistdietryServiceQuery,
useGetcemenuhighlightQuery,
useCeEditAdditionalDetailsMutation,
useTsEditAdditionalDetailsMutation,
useCeEditEcosystemCriteriaMutation,
useTsEditEcosystemCriteriaMutation,
useFsEditAdditionalDetailsMutation,
useFSEditEcosystemCriteriaMutation,
useCeUserEditMutation,
useGetStatesQuery,
useUserEditMutation,
useUserProfileUpdateMutation,
useGetBranchListQuery,
useBranchDeleteMutation,
useEmailNotificationStatusMutation,
useGetEmailNotificationStatusQuery,
useAccountChangeProfileMutation,
useGetAccountHolderDataQuery,
useAccountDeleteMutation,
useAccountChangePasswordMutation,
useNotificationMarkAllReadMutation,
useNotificationMarkReadBusinessMaterialMutation,
useNotificationMarkReadDirectoryMutation,
useNotificationMarkReadEcosystemMutation,
useNotificationMarkReadTechnicalMutation,
useNotificationMarkReadWebsiteMutation,
useGetMediaGallerybyUserIdfsQuery,
useGetMediaGallerybyUserIdtsQuery
} = userapi;
