import React from "react";
import { Row, Col } from "react-bootstrap";
import CardImg from "../Assets/Images/default-placeholder.svg"; // Fallback placeholder
import "../Styles/Userdetail.css";
import { useGetAllMaterialQuery } from "../Services/SecondaryAPI";
import { useParams, Link, useNavigate } from "react-router-dom";

function BusinessmaterialDetails({ branchId, userId }) {
  const { id } = useParams();
  const { data, isLoading, error } = useGetAllMaterialQuery({
    branchId,
    userId,
  });

  console.log("branchId", branchId)
  console.log("userId", userId)
const navigate = useNavigate()
const handleNavigate= (id)=>{
  // navigate(`/businessMaterialView/${id}`)
  navigate(`/businessMaterialView/${id}`, {
    state: { branchId, userId},
  });
}
  console.log("branchId", branchId)
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>No Data available: {error.message}</p>;

  return (
    <div className="user-businessmaterial-detail">
      <Row>
        {data?.data?.map((material) => (
          <Col sm={12} md={4} lg={4} key={material.id}>
            <Link
              to={{
                pathname: `/businessMaterialView/${material.id}/${branchId}/${userId}`}}
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src={material.image || CardImg}
                    alt={material.title}
                    className="uploaded-images"
                  />
                </div>
                <div className="card-body">
                  <p>{material.title}</p>
                  
                </div>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default BusinessmaterialDetails;
