
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// const baseUrl = process.env.REACT_APP_SUPPORT_SERVER;
const baseUrl = process.env.REACT_APP_ADMIN_SERVER;
  
export const supportloginapi = createApi({
  reducerPath: 'websiteapi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      const token = localStorage.getItem('SupportAccessToken');
      console.log('Access Token:', token); 
       
      const noAuthEndpoints = ['submitLogin'];
      if (token && !noAuthEndpoints.includes(endpoint)) {
        headers.set('authorization', `Bearer ${token}`);
      }
      
      return headers;
    },
  }),
  endpoints: (builder) => ({
    submitLogin: builder.mutation({
      query: (newPost) => ({
        url: 'api/supportuser/support-login',
        method: 'POST',
        body: newPost,
      }),
    }),
    getAllTickets: builder.query({
      query: () => `api/supportuser/categories/tickets`,
    }),

    refreshtoken: builder.mutation({
      query: (postData) => ({
        url: `api/supportuser/support_refresh`, 
        method: 'POST',  
        body: postData,  
      }),
    }),

  }),
});

export const {
  useSubmitLoginMutation,
  useRefreshtokenMutation
  // useGetAllTicketsQuery,
} = supportloginapi;
