import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Tabs, Tab, Table } from "react-bootstrap";
import SearchIcon from "../Assets/Images/search-img.svg";
import "../Styles/Chat.css";
import { Link, useLocation } from "react-router-dom";
import BackIcon from "../Assets/Images/back-icon.svg";
import ActionIcon from "../Assets/Images/action_icon.svg";
import Person1 from "../Assets/Images/default-profile-img.png";
import Person2 from "../Assets/Images/default-profile-img.png";
import Person3 from "../Assets/Images/default-profile-img.png";
import { useNavigate } from "react-router-dom";
import { useGetChatPostbyIdQuery , useSubmitPostCommentMutation , useDeletePostbyPostIdMutation} from "../Services/CommunityApi"
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const usersData = [
  {
    category: "Barista",
    noofPosts: 20,
    lastposted: "Jan 4, 2024",
    author: "@john.doe",
  },
  {
    category: "Dashboard screenshot",
    noofPosts: 30,
    lastposted: "Jan 4, 2024",
    author: "@jane.smith",
  },
  {
    category: "Dashboard prototype recording",
    noofPosts: 40,
    lastposted: "Jan 4, 2024",
    author: "@mary.jones",
  },
  // Add more data as needed
];

function Chat() {
  const [key, setKey] = useState("allTopics");
  const [showAddNewTopicModal, setshowAddNewTopicModal] = useState(false);
  const [showTypeMessageForm, setshowTypeMessageForm] = useState(false);
  const [showCommentbox, setShowCommentbox] = useState(false);
  const [errors, setErrors] = useState({});

  const [submitForm, { isLoading: formsubmitting, isError, isSuccess, error }] =
  useSubmitPostCommentMutation();

  const [deletePost, { }] =
  useDeletePostbyPostIdMutation();

  
  const validateForm = () => {
    const formErrors = {};
    if (!formData.message) formErrors.message = "Field is  required.";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const [formData, setFormData] = useState({
    message: "",
  });

  const { id } = useParams();
  const { data: postData, refetch } = useGetChatPostbyIdQuery(id);
  console.log("id", id);
  console.log("postData", postData);
  const navigate = useNavigate();

  const handleAddNewTopic = () => {
    setshowAddNewTopicModal(true);
  };
  const handleclodeAddnewTopicModal = () => {
    setshowAddNewTopicModal(false);
  };
  const goBack = () => {
    navigate(-1);
  };
  const handleShowTypeMessageForm = () => {
    setshowTypeMessageForm(true);
  };
  const handleHideTypeMessageForm = () => {
    setshowTypeMessageForm(false);
  };
  const handleshowCommentDiv = () => {
    setShowCommentbox(true);
  }
  const handleHideCommentDiv = () => {
    setShowCommentbox(false);
  }
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };



  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      let postData = {
        post_id: id,
        comment: formData.message,
      };
      try {
        const response = await submitForm(postData).unwrap();
        // alert("Comment added successfully")
        Swal.fire({
          title: "Comment added successfully",
          text: "",
          icon: "success"
        });
        refetch();
        setFormData({
          message: "",
        });
        setShowCommentbox(false);
      } catch (err) {
        // console.error("Failed to submit form:", err);
        // alert("Failed to comment")
        Swal.fire({
          title: "Failed to submit form:",
          text: "",
          icon: "error"
        });
      }
    }
  };

  const handleDeleteCurrentPost = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure you want to delete this post?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });
  
    if (result.isConfirmed) {
      try {
        const response = await deletePost(id).unwrap();
        Swal.fire({
          title: "Post deleted successfully",
          icon: "success",
        });
        refetch();
        navigate("/dashboard")
        setFormData({
          message: "",
        });
        setShowCommentbox(false);
      } catch (err) {
        Swal.fire({
          title: "Failed to delete post",
          icon: "error",
        });
      }
    }
  };
  
  return (
    <div className="chat-list chat-list-view">
      <div className="chat-list-tab">
        <Row className="category-search">
          <Col xs={12} sm={12} g={12} md={12}>
            <h5>
              <Link onClick={goBack}>
                <img src={BackIcon} alt="BackIcon" />
              </Link>
              {postData?.post?.post_title}
            </h5>
          </Col>
        </Row>
        <div className="chat-list-reply-div">
          <div className="comment-section">
            <div className="d-flex">
              <div className="d-flex">
                <div>
                  <img src={postData?.post?.author_profile_image || Person1} alt="Person1" width={40} height={40} />
                </div>
                <div className="profile">
                  <p className="person-name"> {postData?.post?.author_name}</p>

                  <p className="date">{postData?.post?.post_created_at ? new Date(postData?.post?.post_created_at).toLocaleDateString() : ""}</p>
                </div>
              </div>

              {/* <img src={ActionIcon} alt="ActionIcon" /> */}
            </div>
            <h5>{postData?.post?.post_title}</h5>
            <p className="comments">
              {postData?.post?.post_content}
            </p>

            <hr />
            <div className="chat-reply-delete-div">
              <p className="reply-btn" onClick={()=>handleDeleteCurrentPost(postData?.post?.post_id)}>Delete</p>
              <p className="reply-btn" onClick={handleshowCommentDiv}>Reply</p>
            </div>

            {showCommentbox && <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    placeholder="Type your message here ..."
                    onChange={handleChange}
                    value={formData.message}
                    name="message"
                  />
                  {errors.message && <p className="error">{errors.message}</p>}
                  <Row className="action-btn">
                    <Col xs={12} sm={12} lg={12} md={12} className="text-end">
                      <button type="button" className="btn-outline" onClick={handleHideCommentDiv}> 
                        Cancel
                      </button>
                      <button type="submit" className="btn-primary">
                        Comment
                      </button>
                    </Col>
                    {formsubmitting && <div class="loader"></div>}
                  </Row>
                </form>}
          </div>
          <div className="already-added-comments">
            {postData?.post?.comments.map((comment) => (
              <div key={comment.comment_id} className="comments">
                <div className="br-l">
                  <div className="d-flex">
                    <div className="d-flex">
                      <div>
                        {/* Display author profile icon based on if they are admin */}
                        <img src={Person2} alt={comment.comment_author} width={40} height={40} />
                      </div>
                      <div className="profile">
                        <p className="person-name">{comment.comment_author}</p>
                        <p className="date">{comment.comment_created_at? new Date(comment.comment_created_at).toLocaleString() : ""}</p>
                      </div>
                    </div>
                    {/* <img src={ActionIcon} alt="ActionIcon" /> */}
                  </div>
                  <p className="comments pt-10">{comment.comment_content}</p>
                  <hr />
                  <p className="posted-on">Posted on {new Date(comment.comment_created_at).toLocaleDateString()}</p>
                </div>

                {/* Render Replies */}
                {comment.replies && comment.replies.length > 0 && (
                  <div className="sub-comments">
                    {comment.replies.map((reply) => (
                      <div key={reply.reply_id} className="br-l">
                        <div className="d-flex">
                          <div>
                            <p className="person-name">{reply.reply_author}</p>
                            <p className="date">{new Date(reply.reply_created_at).toLocaleString()}</p>
                          </div>
                          {/* <img src={ActionIcon} alt="ActionIcon" /> */}
                        </div>
                        <p className="comments pt-10">{reply.reply_content}</p>
                        <hr />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
