import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/Login.css";
import { useSubmitLoginMutation } from "../Services/SupportLoginAPI"
import { useNavigate } from "react-router-dom";
import Logo from "../Assets/Images/logo-shade.svg";
import Swal from 'sweetalert2';

function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const formErrors = {};
    if (!formData.email) formErrors.email = "Email is required.";
    if (!formData.password) formErrors.password = "Paasword is required.";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (validateForm) {
  //   }
  // };

  const [submitForm, { isLoading, isError, isSuccess, error }] = useSubmitLoginMutation();

  const handleSubmit = async (e) => {
    const un = "techteam"
    e.preventDefault();
    if (validateForm()) {
      let postData = {
        email: formData.email,
        password: formData.password,
        username: un
      }
      try {
        const response = await submitForm(postData).unwrap();
        localStorage.setItem("SupportAccessToken", response.access_token);
        localStorage.setItem("SupportrefreshToken", response.refresh_token);
        localStorage.setItem("supportRole", response.support_role_name);
        console.log("Login Successfull", response.access_token);
        setFormData({
          email: "",
          password: "",
        });

        const timer = setTimeout(() => {
          navigate("/dashboard");
        }, 3000);
      } catch (err) {
        console.error("Failed to submit form:", err);
      }

    }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "success",
        title: "Signed in successfully",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
    }
  }, [isSuccess]);

  return (
    <div className="login">
      <Row>
        <Col xs={12} md={5} sm={12} lg={5}>
          <div className="login-bg">
            <div className="logo-div">
              <img src={Logo} alt="logo" className="img-fluid" />
            </div>
            <div className="login-bg-text">
              <h2>
                Management & Support <br />Portal
              </h2>
              <p>
                Discover the Breakthrough You've Been Looking For <br />with Australia's Premier Cafe Industry Support <br />Platform
              </p>
            </div>
            {/* <img src={VectorImg} alt="VectorImg" className="vector-img-login"/> */}
          </div>
        </Col>
        <Col xs={12} md={7} sm={12} lg={7} className="left-div">
          {/* <p className="go-to-register">
            Don't have an account? <span>Register</span>
          </p> */}
          <div className="login-form">
            <h3>Support Login</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-control">
                <label>Email</label>
                <br />

                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email id"
                />
                {errors.email && (
                  <p className="error">{errors.email}</p>
                )}
              </div>
              <div className="form-control">
                <label>Password</label>
                <br />
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Password"
                />
                {errors.password && (
                  <p className="error">{errors.password}</p>
                )}
              </div>
              <div className="form-control">
                <button type="submit" className="login-submit-btn">
                  Login
                </button>
              </div>
              {isError && (
                <p style={{ color: "red" }}>
                  {"Username or password is incorrect." || "Login Failed"}
                </p>
              )}
              {/* {isSuccess && (
                    <p style={{ color: "green" }} className="isSuceesMsg">
                      Login Successfull!
                    </p>
                  )} */}
              {isLoading && <div class="loader"></div>}
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
