import React, { useEffect, useState } from "react";
import BackIMg from "../Assets/Images/back-icon.svg";
import { Row, Col } from "react-bootstrap";
import "../Styles/ViewTicket.css";
import ProfileImg from "../Assets/Images/default-profile-img.png";
import ActionImg from "../Assets/Images/action-img.svg";
import { useParams, Link } from "react-router-dom";
import { useGetTicketbyIdQuery, useSubmitReplyMutation } from "../Services/SupportAPI";
import Swal from 'sweetalert2';

function ViewTicket() {
  const { id } = useParams();
  const { data, refetch } = useGetTicketbyIdQuery({ id });

  const [formData, setFormData] = useState({ comment: "" });
  const [submitForm, { isLoading, isError, isSuccess, error }] = useSubmitReplyMutation();
  const [userType, setCafeBranch] = useState("")
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    const branchType = data?.ticket?.ticket_user_type;

    if (branchType === "is_cafe_entrepreneurship_user") {
      setCafeBranch("ce");
    } else if (branchType === "is_trade_service_user") {
      setCafeBranch("ts");
    } else {
      setCafeBranch("fs");
    }
  }, [data]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.comment) {
      // alert("Please enter a comment.");
      Swal.fire({
        title: "",
        text: "Please enter a comment!",
        icon: "info"
      });
      return;
    }

    try {
      const postData = {
        comment: formData.comment,
      };

      const response = await submitForm({id, postData }).unwrap();

      // console.log("Comment submission successful", response);
      setFormData({ comment: "" });

      // Optionally refetch data to update comments section
      refetch();
      Swal.fire({
        title: "",
        text: "Reply submitted successfully!",
        icon: "success"
      });
    } catch (err) {
      // console.error("Failed to submit form:", err);
      Swal.fire({
        title: "",
        text: "Failed to submit the reply!",
        icon: "error"
      });
    }
  };

  if (!data) {
    return <p>Loading...</p>;
  }

  const ticket = data.ticket;
  const comments = ticket.comments || [];
const branchid = data?.ticket?.directory_listing_id[0];
const branchType = ticket?.ticket_user_type;

const getPath = () => {
  if (userType === "ce") {
    return `/cafe-details/${branchid}/ce`;
  } else if (userType === "ts") {
    return `/trade-details/${branchid}/ts`;
  } else if (userType === "fs") {
    return `/food-details/${branchid}/fs`;
  } else {
    return `/other-details/${branchid}`;  // Default fallback path
  }
};
  return (
    <div className="view-ticket">
      <h4>
        <Link to="/dashboard">
          <img src={BackIMg} alt="Back" />
        </Link>
        {ticket.posted_by.name}
      </h4>
      <div className="content-wrapper">
        <div className="d-flex justify-space-between">
          <div className="d-flex">
            <div>
              <img src={ProfileImg} alt="Profile" width={40} height={40}/>
            </div>
            <div className="mgl-20">
            {/* {ticket?.ticket_user_type === "is_trade_service_user" ? } */}
            {/* <Link to={`/cafe-details/${branchid}/${userType}`}>
             <p className="person-name">{ticket.posted_by.name}</p></Link> */}
              <Link to={getPath()}>
      <p className="person-name">{data?.ticket?.posted_by?.name}</p>
    </Link>
              <p className="ticket-date">{ticket.posted_date}</p>
            </div>
          </div>
          {/* <div>
            <img src={ActionImg} alt="Action" />
          </div> */}
        </div>

        {/* Ticket Description */}
        <Row className="ticket-sub">
          <p>{ticket.description}</p>
        </Row>

        {/* Comments Section */}
        <Row>
          {comments.map((comment) => (
            <div key={comment.comment_id} className="comment-section">
              <div className="d-flex">
                
                {/* <img src={ProfileImg} alt="Comment Author" /> */}
                <img
                  src={comment?.profile_image ? comment.profile_image : ProfileImg}
                   alt="Comment Author"
                   width={40} height={40}
                   />

                <div className="mgl-20">
                  <p className="person-name">{comment.posted_by.name}</p>
                  <p className="ticket-date">{comment.comment_date}</p>
                  <p>{comment.comment}</p>
                </div>
              </div>

              {/* Replies */}
              {comment.replies && comment.replies.length > 0 && (
                <div className="replies-section">
                  {comment.replies.map((reply) => (
                    <div key={reply.reply_id} className="reply">
                      <div className="d-flex">
                        <img src={ProfileImg} alt="Reply Author" width={40} height={40}/>
                        <div className="mgl-20">
                          <p className="person-name">{reply.reply_author}</p>
                          <p className="ticket-date">
                            {new Date(reply.reply_created_at).toLocaleString()}
                          </p>
                          <p>{reply.reply_content}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </Row>

        {/* Comment Form */}
        <Row>
          <form onSubmit={handleSubmit}>
            <div className="form-control">
              <input
                type="text"
                name="comment"
                placeholder="Type your message here ..."
                value={formData.comment}
                onChange={handleInputChange}
              />
            </div>
            <div className="text-end">
              <button
                type="button"
                className="btn-outline mgr-10"
                onClick={() => setFormData({ comment: "" })}
              >
                Cancel
              </button>
              <button type="submit" className="btn-primary" disabled={isLoading}>
                {isLoading ? "Submitting..." : "Comment"}
              </button>
            </div>
          </form>
          {/* {isError && <p className="error">Failed to submit the comment. Please try again.</p>}
          {isSuccess && <p className="success">Comment submitted successfully!</p>} */}
        </Row>
      </div>
    </div>
  );
}

export default ViewTicket;
