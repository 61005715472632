import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_COMMUNITY_SERVER;

export const communityapi = createApi({
  reducerPath: 'communityapi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      const token = localStorage.getItem('SupportAccessToken');
      console.log('Access Token:', token); 
      
      const noAuthEndpoints = ['submitLogin'];
      if (token && !noAuthEndpoints.includes(endpoint)) {
        headers.set('authorization', `Bearer ${token}`);
      }
      
      return headers;
    },
  }),
  endpoints: (builder) => ({
    
    getAllcategories: builder.query({
      query: () => `api/admin/list-chatcategories`,
    }),
    addChatcategory: builder.mutation({
        query: ({category_name }) => ({
          url: `api/admin/store-chat-categories`, 
          method: 'POST',  
          body: { category_name },  
        }),
      }), 
      getAlluserchat: builder.query({
        query: (id) => `api/list-all-posts/${id}`,
      }),
      getUserchatdetails: builder.query({
        query: (id) => `api/admin/admin-post-by-categories?chat_category_id=${id}`,
      }),
      deletePost: builder.mutation({
        query: (id) => ({
          url: `api/admin/delete-post?post_id=${id}`,
          method: 'DELETE',
        }),
      }),
      getUserchatcomentslist: builder.query({
        query: (id) => `api/admin/posts-admin/${id}`,
      }),
      addPostcomment: builder.mutation({
        query: (postData ) => ({
          url: `api/admin/admin-replay_post`, 
          method: 'POST',  
          body: postData ,  
        }),
      }), 
      getNoticebaordcategories: builder.query({
        query: () => `api/display-notice-categories`,
      }),
      getListNotice: builder.query({
        query: ({branchId,userId,categoryId}) => `api/list_notices?user_id=${userId}&category_id=${categoryId}&directory_listing_id=${branchId}`,
      }),
      getNoticebaordall: builder.query({
        query: (selectedCategoryId) => `api/admin/get-notices-admin?noticeCategory=${selectedCategoryId}`,
      }),
      getChatPostbyId : builder.query({
        query: (id) => `api/post-support/${id}`,  // Added backticks
      }),

      submitPostComment: builder.mutation({
        query: (postData) => ({
          url: `api/support-replay_post`, 
          method: 'POST',  
          body: postData ,  
        }),
      }), 
      DeletePostbyPostId: builder.mutation({
        query: (id) => ({
          url: `api/delete-posts/${id}`,
          method: 'DELETE',
        }),
      }),
  }),
});

export const {
  useGetAllcategoriesQuery,
  useAddChatcategoryMutation,useGetAlluserchatQuery,
  useGetUserchatdetailsQuery,useDeletePostMutation,
  useGetUserchatcomentslistQuery,useAddPostcommentMutation,
  useGetNoticebaordcategoriesQuery,useGetNoticebaordallQuery,
  useGetListNoticeQuery,
  useGetChatPostbyIdQuery,
  useSubmitPostCommentMutation,
  useDeletePostbyPostIdMutation
} = communityapi;
