import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { supportapi } from "../Services/SupportAPI";
import { supportloginapi } from "../Services/SupportLoginAPI";
import { userapi } from "../Services/UserAPI";
import { secondayapi } from "../Services/SecondaryAPI";
import { communityapi } from "../Services/CommunityApi";

export const store = configureStore({
  reducer: {
    [supportapi.reducerPath]: supportapi.reducer,
    [supportloginapi.reducerPath]: supportloginapi.reducer,
    [userapi.reducerPath]: userapi.reducer,
    [secondayapi.reducerPath]: secondayapi.reducer,
    [communityapi.reducerPath]: communityapi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(supportapi.middleware,supportloginapi.middleware,userapi.middleware, communityapi.middleware,secondayapi.middleware),
});

setupListeners(store.dispatch);
