import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_SUPPORT_SERVER;

export const supportapi = createApi({
  reducerPath: 'supportapi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      const token = localStorage.getItem('SupportAccessToken');
      console.log('Access Token:', token); 
      
      const noAuthEndpoints = ['submitLogin'];
      if (token && !noAuthEndpoints.includes(endpoint)) {
        headers.set('authorization', `Bearer ${token}`);  // Fix here with backticks
      }
      
      return headers;
    },
  }),
  endpoints: (builder) => ({
    submitLogin: builder.mutation({
      query: (newPost) => ({
        url: 'api/supportuser/support-login',
        method: 'POST',
        body: newPost,
      }),
    }),
    submitReply: builder.mutation({
      query: ({ id, postData }) => ({
        url: `api/replay-support/${id}/comments`,
        method: 'POST',
        body: postData,
      }),
    }),
    getAllTickets: builder.query({
      query: ({page,selectedStatus}) => {
        const url = selectedStatus ? `api/supportuser/list-ticketbyrole?page=${page}&status=${selectedStatus}` : `api/supportuser/list-ticketbyrole?page=${page}`;
        return url;
      },
    }),
    getTicketbyId: builder.query({
      query: ({ id }) => `api/view-ticket/${id}`,  // Added backticks
    }),

  }),
});

export const {
  useSubmitLoginMutation,
  useGetAllTicketsQuery,
  useGetTicketbyIdQuery,
  useSubmitReplyMutation,
  useGetChatPostbyIdQuery
} = supportapi;
