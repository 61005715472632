import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/BusinessMaterial.css";
import { Tab, Tabs, Nav } from "react-bootstrap";
import BookmarkIcon from "../Assets/Images/bookmark.svg";
import BookmarkFilled from "../Assets/Images/bookmark-filled.svg";
import FilterIcon from "../Assets/Images/filter-icon.svg";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetSubCategorybyMaterialIdQuery,
  useListUnSavedMaterialRecordsMutation,
  useListSavedMaterialRecordsQuery,
  useGetAllRecordsbyMaterialIdQuery,
  useGetMaterialTypeQuery,
  useGetMaterialTypebyCategoryIdQuery,
  useGetRecordsByIdQuery,
  useSaveRecordsMutation,
} from "../Services/SecondaryAPI";
import DefaultPdfImg from "../Assets/Images/defaultpdf-img.png";
import BackIcon from "../Assets/Images/back-icon.svg";
import { useLocation } from "react-router-dom";
import Swal from 'sweetalert2';

function AllMaterialCafeStratup() {
  const { id, branchid, userid } = useParams();
  const [mainKey, setMainKey] = useState(1);
  const [subKey, setSubKey] = useState("Saved");
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const dropdownRef = useRef(null);
  const [filteredSavedRecords, setFilteredSavedRecords] = useState("");
  const [filteredSEditedRecords, setFilteredEditedRecords] = useState("");
  const [upgradeModal, setupgradeModal] = useState(false);
  // const location = useLocation();
  // const { branchId, userId } = location.state || {};
  const navigate = useNavigate();
  const accountHolderId = localStorage.getItem("accountHolderId");
  const subscription_type = localStorage.getItem(
    "subscription_type_current_selected"
  );
  // const subscriptionType = localStorage.getItem("subscription_type_current_selected");
  const subscriptionType = subscription_type;

  const { data: subCategories, isLoading } = useGetSubCategorybyMaterialIdQuery(
    { id }
  );
  const { data: SavedRecords, refetch: refetchSavedRecords } =
    useListSavedMaterialRecordsQuery({
      userId: userid,
      businessmaterialid: id,
      subcategory_id: mainKey,
      directoryListingId: branchid,
      subcsriptionType: subscriptionType,
      status: 1,
    });

  const { data: editedRecords, refetch: refetchEditedRecords } =
    useListSavedMaterialRecordsQuery({
      userId: userid,
      businessmaterialid: id,
      subcategory_id: mainKey,
      directoryListingId: branchid,
      subcsriptionType: subscriptionType,
      status: 2,
    });

  useEffect(() => {
    const fetchData = async () => {
      await refetchSavedRecords();
    };

    fetchData();
  }, [mainKey]);

  useEffect(() => {
    const fetchData = async () => {
      await refetchEditedRecords();
    };

    fetchData();
  }, [mainKey]);

  useEffect(() => {
    console.log("SavedRecords", SavedRecords);
    console.log("mainKey", mainKey);

    if (SavedRecords?.data) {
      const filteredSavedRecords = SavedRecords.data.filter(
        (record) => record?.record?.subcategory_id === mainKey
      );

      console.log("filteredSavedRecords", filteredSavedRecords);
      setFilteredSavedRecords(filteredSavedRecords);
    }
  }, [SavedRecords, mainKey]);

  useEffect(() => {
    console.log("SavedRecords", SavedRecords);
    console.log("mainKey", mainKey);

    if (editedRecords?.data) {
      const filterededitedRecords = editedRecords.data.filter(
        (record) => record?.record?.subcategory_id === mainKey
      );

      console.log("filterededitedRecords", filterededitedRecords);
      setFilteredEditedRecords(filterededitedRecords);
    }
  }, [editedRecords, mainKey]);

  const handleTabSelect = (k) => {
    console.log("k", k);
    setMainKey(k);
  };

  const toggleDropdown = () => {
    setIsOpen((prevState) => {
      console.log("Dropdown toggled:", !prevState); // Debugging
      return !prevState;
    });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleSelect = (item) => {
    setActiveItem(item);
    console.log(`${item} selected`);
    setIsOpen(false); // Close dropdown after selection
  };
  useEffect(() => {
    if (subscription_type == "lite") {
      setupgradeModal(true);
    }
  }, [subscription_type]);

  const handleCloseUpgradModal = () => {
    setupgradeModal(false);
  };
  const showupgradeModal = () => {
    setupgradeModal(true);
  };
  const handleBackClick = () => {
    navigate(-1);
  };
  return (
    <div className="my-material-cafestartup ">
      <div className="back-page-icon">
        <img src={BackIcon} alt="Back" onClick={handleBackClick} />
      </div>
      <div className="filter-dtpdn">
        <div className="custom-dropdown" ref={dropdownRef}>
          {/* <button className="dropdown-toggle" onClick={toggleDropdown}>
            <img src={FilterIcon} alt="FilterIcon" />
            Filter
          </button> */}
        </div>
      </div>
      <div className="vertical-tabs-container">
        {isLoading && <div class="loader"></div>}
        <Tabs
          id="vertical-tabs"
          activeKey={mainKey}
          onSelect={handleTabSelect}
          className="mb-3"
          variant="pills"
        >
          {subCategories &&
            subCategories?.data?.subcategories?.map((subCategory) => (
              <Tab
                key={subCategory.id}
                eventKey={subCategory.id}
                title={subCategory.subcategory_title}
              >
                <HorizontalTabs
                  activeKey={subKey}
                  onSelect={(k) => setSubKey(k)}
                  mainKey={mainKey}
                  editedRecords={editedRecords}
                  filteredSavedRecords={filteredSavedRecords}
                  filteredSEditedRecords={filteredSEditedRecords}
                  refetchSavedRecords={refetchSavedRecords}
                  refetchEditedRecords={refetchEditedRecords}
                />
              </Tab>
            ))}
        </Tabs>
      </div>
    </div>
  );
}

function HorizontalTabs({
  activeKey,
  onSelect,
  SavedRecords,
  mainKey,
  editedRecords,
  refetchSavedRecords,
  refetchEditedRecords,
  filteredSavedRecords,
  filteredSEditedRecords
}) {
  const [bookmarks, setBookmarks] = useState({
    cafestart: true,
    lease: true,
    // Add more items as needed
  });
  const { id } = useParams();

  const [itemId, setItemId] = useState(null);
  const accountHolderId = localStorage.getItem("accountHolderId");
  const branchId = localStorage.getItem("branchId_current_selected");
  const subscriptionType = localStorage.getItem(
    "subscription_type_current_selected"
  );
  const [triggerUnsave, { isLoading }] =
    useListUnSavedMaterialRecordsMutation();

  const handleBookmarkClick = async (itemKey, item) => {
    const accountHolderId = localStorage.getItem("accountHolderId");
    const branchId = localStorage.getItem("branchId_current_selected");
    const subscriptionType = localStorage.getItem(
      "subscription_type_current_selected"
    );

    const newBookmarks = { ...bookmarks, [itemKey]: !bookmarks[itemKey] };
    setBookmarks(newBookmarks);

    if (!newBookmarks[itemKey]) {
      try {
        const response = await triggerUnsave({
          userId: accountHolderId,
          record_id: item.id,
          directory_listing_id: branchId,
          subscription_type: subscriptionType,
        }).unwrap();

        if (response.success) {
          // console.log("Record successfully unsaved");
          // alert("Record successfully unsaved");
          Swal.fire({
            title: "Record successfully unsaved",
            text: "",
            icon: "success"
          });
          window.location.reload();
        }
      } catch (error) {
        console.error("Failed to unsave record:", error);
        alert("failed");
      }
    }
  };
  const [upgradeModal, setupgradeModal] = useState(false);

  const subscription_type = localStorage.getItem(
    "subscription_type_current_selected"
  );

  useEffect(() => {
    if (subscription_type == "lite") {
      setupgradeModal(true);
    }
  }, [subscription_type]);

  const handleCloseUpgradModal = () => {
    setupgradeModal(false);
  };
  const showupgradeModal = () => {
    setupgradeModal(true);
  };

  const navigate = useNavigate();
  const handleEditAndUpload = (record) => {
    navigate(`/pdf-edit`, { state: { record } });
  };
  const handleTabSelect = (k) => {
    onSelect(k);
    console.log("k", k);
    if (k === "Saved") {
      refetchSavedRecords();
    } else if (k === "edited") {
      refetchEditedRecords();
    }
  };
  return (
    <div
      className={`mb-3 horizontal-tabs my-material-cafestartup ${
        subscription_type === "lite" ? "chat-disable-for-liteuser" : ""
      }`}
    >
      <Tabs
        id="horizontal-tabs"
        activeKey={activeKey}
        onSelect={handleTabSelect}
        className="mb-3"
        variant="pills"
      >
        <Tab eventKey="Saved" title="Saved">
          <div className="tab-content">
            <Row>
              {filteredSavedRecords?.length > 0 ? (
                filteredSavedRecords?.map((record) => (
                  <Col xs={12} sm={12} lg={4} md={4} key={record.id}>
                    <div className="card">
                      <div className="card-img">
                        <img src={DefaultPdfImg} alt="CafestartImg" />
                      </div>
                      <div className="card-body">
                        <h4>{record.title}</h4>
                        <p className="category">{record.subcategory_id}</p>
                        <div className="btns">
                          <a href={record.record.file_path} target="_blank">
                            <button type="button" className="view-btn">
                              View
                            </button>
                          </a>
                        </div>
                        <img
                          src={
                            bookmarks.cafestart ? BookmarkFilled : BookmarkIcon
                          }
                          alt={
                            bookmarks.cafestart
                              ? "BookmarkFilled"
                              : "BookmarkIcon"
                          }
                          className="bookmark-icon"
                          onClick={() => handleBookmarkClick(record.id, record)}
                        />
                      </div>
                    </div>
                  </Col>
                ))
              ) : (
                <p>No records found</p>
              )}
            </Row>
          </div>
        </Tab>
        <Tab eventKey="edited" title="Edited">
          <div className="tab-content">
            <Row>
              {filteredSEditedRecords?.length > 0 ? (
                filteredSEditedRecords?.map((record) => (
                  <Col xs={12} sm={12} lg={4} md={4} key={record.id}>
                    <div className="card">
                      <div className="card-img">
                        <img src={DefaultPdfImg} alt="CafestartImg" />
                      </div>
                      <div className="card-body">
                        <h4>{record.title}</h4>
                        <p className="category">{record.subcategory_id}</p>
                        <div className="btns">
                          <a href={record.record.file_path} target="_blank">
                            <button type="button" className="view-btn">
                              View
                            </button>
                          </a>
                        </div>
                        <img
                          src={
                            bookmarks.cafestart ? BookmarkFilled : BookmarkIcon
                          }
                          alt={
                            bookmarks.cafestart
                              ? "BookmarkFilled"
                              : "BookmarkIcon"
                          }
                          className="bookmark-icon"
                          onClick={() => handleBookmarkClick(record.id, record)}
                        />
                      </div>
                    </div>
                  </Col>
                ))
              ) : (
                <p>No records found</p>
              )}
            </Row>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default AllMaterialCafeStratup;
