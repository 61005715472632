import React from 'react';
import { Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Star from "../Assets/Images/trupoilt.svg"

function Footer() {
  return (
    <footer className="footer-dashboard mt-auto py-3">
      <div className="container-fluid">
      <Row>
        <Col sm={12} lg={8} xs={12} md={8}>
        <div className='left-sec'>
        <p>We'd love it if you'd share the love and invite a friend to join the Clever Cafe community. </p>
        </div>
       
        </Col>
        <Col sm={12} lg={4} xs={12} md={4}>
        <div className='right-sec'>
        {/* <Link>Article</Link>
        <Link>Contact Us</Link> */}
        <a href="https://www.trustpilot.com/review/clevercafecompany.com.au" target='_blank'><img src={Star} alt='Star' className='trustpiolit'/>Trustpilot</a>
        </div>
        </Col>
      </Row>
      </div>
    </footer>
  );
}

export default Footer;
