import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Layout from "../Components/Layout";
import TicketManagement from "../Components/TicketManagement";
import ViewTicket from "../Components/ViewTicket"
import Login from "../Components/Login"
import TradeTicket from "../Components/TradeserviceTicket"
import FoodTicket from"../Components/FoodServiceTicket"
import FoodViewTicket from "../Components/FoodViewTicket"
import TradeViewTicket from "../Components/TradeserviceTicket"
import CafeDetails from "../Components/CafeUserDetails";
import CommunityChatView from "../Components/CommunityChatView";
import CommunityChatReply from "../Components/CommunityChatReply"
import TradeUserDetsils from "../Components/TradeUserDetails"
import FoodUserDetsils from "../Components/FoodUserDetails"
import BusinessMaterialView from "../Components/BusinessMaterialView"
import Notification from "../Components/Notification"

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
       <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<Layout><TicketManagement /></Layout>} />
      <Route path="/view-ticket/:id" element={<Layout><ViewTicket /></Layout>} />
      <Route path="/trade-ticket" element={<Layout><TradeTicket /></Layout>} />
      <Route path="/food-ticket" element={<Layout><FoodTicket /></Layout>} />
      <Route path="/food-view-ticket/:id" element={<Layout><FoodViewTicket /></Layout>} />
      <Route path="/trade-view-ticket/:id" element={<Layout><TradeViewTicket /></Layout>} />
      <Route path="/cafe-details/:id/:type" element={<Layout><CafeDetails /></Layout>} />
      <Route path="/community-chat-view/:id" element={<Layout><CommunityChatView /></Layout>} />
      <Route path="/chatlist-reply/:id" element={<Layout><CommunityChatReply /></Layout>} />
      <Route path="/trade-details/:id/:type" element={<Layout><TradeUserDetsils /></Layout>} />
      <Route path="/food-details/:id/:type" element={<Layout><FoodUserDetsils /></Layout>} />
      <Route path="/businessMaterialView/:id/:branchid/:userid" element={<Layout><BusinessMaterialView /></Layout>} />
      <Route path="/notification" element={<Layout><Notification /></Layout>} />
    </Routes>
  );
}

export default function Routers() {
  return (
    <ScrollToTop />
  );
}
