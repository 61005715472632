import React, { useState } from "react";
import SearchImg from "../Assets/Images/search-img.svg";
import FilterImg from "../Assets/Images/Filters lines.svg";
import { Link } from "react-router-dom";
import { useGetAllTicketsQuery } from "../Services/SupportAPI";


function MediaGallery() {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [page, setPage] = useState(1);
  const { data, isLoading ,refetch} = useGetAllTicketsQuery({selectedStatus, page});

  console.log("ticket count", data?.tickets?.length)
  const totalCount = data?.tickets_count || 0; // Use optional chaining to prevent errors
  const totalPages = Math.ceil(totalCount / 10); 

  const tickets = data?.tickets; 
  const filteredTickets = tickets?.filter((ticket) =>
    ticket.post_user_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isLoading) {
    return <p>Loading...</p>;
  }

const handleResetFilter = () =>{
  setSelectedStatus("")
  refetch();
}

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleStatusClick = (status) => {
    setSelectedStatus(status);
    setIsDropdownOpen(false); 
    console.log("Selected status:", status); 
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePreviousPage = () => {
    if (page > 1) setPage(page - 1);
  };

  const handleNextPage = () => {
    if (page < totalPages) setPage(page + 1);
  };


  return (
    <div>
      <h3>Ticket Management</h3>
      <div className="table-wrapper-div">
        <div className="search-div">
          <div className="form-control-input">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange} 
            />
            <img src={SearchImg} alt="Search" className="search-img" />
          </div>
          <div className="filter-main-div">
            <button className="filter-btn" onClick={toggleDropdown}>
              <img src={FilterImg} alt="Filters" /> Filters
            </button>
            {isDropdownOpen && (
              <div className="dropdown-menu-filter">
                                 <p className="reset-filter text-end" onClick={handleResetFilter}>Reset</p>
                <div onClick={() => handleStatusClick("New")} className="dropdown-item-status">New</div>
                <div onClick={() => handleStatusClick("In Progress")} className="dropdown-item-status">In Progress</div>
                <div onClick={() => handleStatusClick("Resolved")} className="dropdown-item-status">Resolved</div>
              </div>
            )}
          </div>
        </div>
        <div className="table-div">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Ticket no</th>
                <th scope="col">Account holder</th>
                <th scope="col">Sub category</th>
                <th scope="col">Added on</th>
                <th scope="col">Status</th>
                <th scope="col">Last update</th>
                <th scope="col">Image</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {filteredTickets?.map((ticket) => (
                <tr key={ticket.id}>
                  <td>{ticket.ticket_code_ID}</td>
                  <td>
                    {ticket.post_user_name}
                  </td>
                  <td>{ticket.subcategory_name}</td>
                  <td>{new Date(ticket.created_at).toLocaleDateString()}</td>
                  <td className="d-flex">
                    <button className="new-status">
                      <p className="violet-circle"></p>
                      <span>{ticket.status}</span>
                    </button>
                  </td>
                  <td>{new Date(ticket.updated_at).toLocaleDateString()}</td>
                  <td className="text-end">
                 {ticket.file_path && <a href={ticket.file_path} target="_blank">
                      <button className="btn-primary-text">View image</button>
                    </a>}
                  </td>
                  <td className="text-end">
                    <Link to={`/view-ticket/${ticket.id}`}>
                      <button className="btn-view">View</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table><br /><br />
        </div>

        <div className="prev-next-btn text-end">
            <button
              type="button"
              onClick={handlePreviousPage}
              disabled={page === 1}
              className={page === 1 ? "disabled" : ""}
            >
              Previous
            </button>

            {/* Pagination Numbers */}
            <div className="pagination-numbers">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageClick(index + 1)}
                  className={page === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              ))}
            </div>

            <button
              type="button"
              onClick={handleNextPage}
              disabled={page === totalPages}
              className={page === totalPages ? "disabled" : ""}
            >
              Next
            </button>
            <p>{`Page ${page} of ${totalPages}`}</p>
          </div>

      </div>
    </div>
  );
}

export default MediaGallery;
