// ModalComponent.jsx
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";


import { useAccountDeleteMutation } from "../Services/UserAPI";

const ModalComponent = ({
  showAccountDeleteMOdal,
  handleCloseAccountDeleteModal,
  userId
}) => {

  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useAccountDeleteMutation();
  const navigate = useNavigate();
  const [isOtherChecked, setIsOtherChecked] = useState(false);
  const [otherReason, setOtherReason] = useState("");

  const [isAlternativeSolutionChecked, setisAlternativeSolutionChecked] = useState(false);
  const [alternativeSolution, setAlternativeSolution] = useState("");

  const handleCheckboxChange = (e) => {
    setIsOtherChecked(e.target.checked);
    if (!e.target.checked) {
      setOtherReason("");
    }
  };

  const handleCheckboxChangeAlternativeSolution = (e) => {
    setisAlternativeSolutionChecked(e.target.checked);
    if (!e.target.checked) {
      setAlternativeSolution("");
    }
  };

  const handlecloseModal = () => {
    handleCloseAccountDeleteModal();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await submitForm(userId).unwrap();
      alert("Account deleted successfully")
    } catch (err) {
      console.error("Failed to submit form:", err);
      alert("Failed to delete account");
      handleCloseAccountDeleteModal();
      setIsOtherChecked(false)
    }

  };
  useEffect(() => {
    if (isSuccess) {
      window.location.pathname("/dashboard")
    }
  }, [isSuccess])
  return (
    <>
      <Modal
        show={showAccountDeleteMOdal}
        onHide={handleCloseAccountDeleteModal}
        backdrop="true"
        dialogClassName="add-new-modal"
      >
        <Modal.Body>
          <h2>Delete Account</h2>
          <p>
            Delete all listings under your account and deactivate your account.
          </p>
          <p className="small-text">
            <span>Please note:</span> When you delete listings or your entire account, this
            action is final and cannot be undone. If you choose to rejoin later,
            you will need to start from scratch.
          </p>
          <h2>Reason for Deleting Your Account</h2>
          <form onSubmit={handleSubmit} className="deleteaccont-modal">
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} className="delete-accnt-criteria">

                <input type="checkbox" /><label>Business No Longer Active</label>

              </Col>
              <Col xs={12} sm={12} md={12} lg={12} className="delete-accnt-criteria">

                <input type="checkbox" /><label>Service Fulfilled</label>

              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>

                <input type="checkbox" onChange={handleCheckboxChangeAlternativeSolution}
                  checked={isAlternativeSolutionChecked} /><label className="other-specify-check">Switching to Alternative Solutions (Please Specify)</label>
                {isAlternativeSolutionChecked && (
                  <input
                    type="text"
                    placeholder="Please specify your reason"
                    value={alternativeSolution}
                    onChange={(e) => setAlternativeSolution(e.target.value)}
                  />
                )}
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} className="delete-accnt-criteria">

                <input type="checkbox" /><label>Platform Not Meeting Expectations</label>

              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>

                <input type="checkbox" onChange={handleCheckboxChange}
                  checked={isOtherChecked} /><label className="other-specify-check">Other (Please Specify)</label>
                {isOtherChecked && (
                  <input
                    type="text"
                    placeholder="Please specify your reason"
                    value={otherReason}
                    onChange={(e) => setOtherReason(e.target.value)}
                  />
                )}
              </Col>
            </Row>
            <Row className="action-btn">
              <Col xs={12} sm={12} lg={12} md={12} className="text-end">
                <button
                  type="button"
                  className="btn-outline"
                  onClick={handlecloseModal}
                >
                  Cancel
                </button>
                <button type="submit" className="btn-primary ml-10">
                  Delete
                </button>
              </Col>
              {isError && (
                <p style={{ color: "red" }}>
                  Error: {error?.data?.error || "Failed to submit form"}
                </p>
              )}
              {isSuccess && (
                <p style={{ color: "green" }} className="isSuceesMsg">
                  Form submitted successfully!
                </p>
              )}
              {isLoading && <div class="loader"></div>}
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalComponent;
