import React, { useState } from "react";
import BackIcon from "../Assets/Images/back-icon.svg";
import { useNavigate } from "react-router-dom";
import "../Styles/Userdetail.css";
import Tab1 from "../Components/FoodDetails";
import Tab2 from "../Components/FoodmediaGallery";
import Tab3 from "../Components/CommunityDetails";
import Tab4 from "../Components/AccountSettingsDetails";
import { useParams } from "react-router-dom";
import { useGetUserdetailsQuery } from "../Services/UserAPI";
import DefaultLogo from "../Assets/Images/default-logo.png";

function UserLocationDetails() {
  const { id } = useParams();
  const { type } = useParams();
  const { data, refetch } = useGetUserdetailsQuery({ id, type });
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const [activeTab, setActiveTab] = useState(0);

  // Mapping of tabs to their respective components
  const tabPages = [
    { title: "Food Details", component: <Tab1 id={id} type={type} userId={data?.branch?.user} /> }, // Pass id and type to Tab1
    { title: "Media gallery", component: <Tab2 branchId={id} userId={data?.branch?.user} /> },
    { title: "Account Settings", component: <Tab4 branchId={id} userId={data?.branch?.user}/> },
  ];

  return (
    <div>
      <div className="back-page-icon">
        <img src={BackIcon} alt="Back" onClick={handleBackClick} />
      </div>
      <div className="content-wrapper-bg">
      <div className="d-flex">
          <div>
            <img src={data?.additional_detail?.logo || DefaultLogo} alt="DefaultLogo" className="company-logo" />
          </div>
          <div className="ml-20">
            <p className="user-detail-branch-name">{data?.branch?.business_name}</p>
            <p className="user-detail-address">{data?.branch?.address}</p>
          </div>
        </div>
        <div>
          {/* Tab buttons with circles */}
          <div className="tab-buttons">
            {tabPages.map((tab, index) => (
              <div
                key={index}
                className={`tab ${activeTab === index ? "active" : ""}`}
              >
                <button
                  className={activeTab === index ? "active-tab" : ""} 
                  onClick={() => setActiveTab(index)}
                >
                  <span className="circle">{index + 1}</span>
                  {tab.title}
                </button>
              </div>
            ))}
          </div>

          <div className="tab-content">{tabPages[activeTab].component}</div>
        </div>
      </div>
    </div>
  );
}

export default UserLocationDetails;
