import { useState } from "react";
import BackIcon from "../Assets/Images/back-icon.svg";
import { useNavigate , Link} from "react-router-dom";
import "../Styles/CommunityChatView.css";
import DefaultImg from "../Assets/Images/default-profile-img.png";
import ActionImg from "../Assets/Images/action_icon.svg";
function CommunityChatView() {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };
  const [showDelete, setShowDelete] = useState(false);
  const handleImageClick = () => {
    setShowDelete(!showDelete); // Toggle the delete div
  };
  return (
    <div className="comminity-chat-view">
      {" "}
      <div className="back-page-icon">
        <p>
          <img src={BackIcon} alt="" onClick={handleBackClick} /> Barista
        </p>
        <div>
          <div className="chatlist-view-card">
            <div className="d-flex align-items-space-between">
              <h5>The automobile layout consists of a front-engine design.</h5>
              <div className="delete-icon-div">
                {/* <img
                  src={ActionImg}
                  alt="ActionImg"
                  className="actionimg"
                  onClick={handleImageClick}
                  style={{ cursor: "pointer" }}
                /> */}

                {showDelete && (
                  <div className="delete-icon">
                    <img src="/path_to_delete_icon.png" alt="Delete" />{" "}
                    {/* Replace with your delete icon */}
                    {/* Optional additional functionality, like a delete button */}
                  </div>
                )}
              </div>
              {/* <img src={ActionImg} alt="ActionImg" className="actionimg"/> */}
            </div>

            <p className="description">
              Lorem ipsum dolor sit amet consectetur. Dignissim tincidunt sed
              vitae vehicula. Congue lacus magnis viverra risus aliquam enim.
              Sed nunc rhoncus nisl interdum augue mattis lacinia adipiscing
              mauris.
            </p>

            <hr />
            <div className="d-flex align-items-space-between">
              <div className="d-flex">
                <img src={DefaultImg} alt="DefaultImg" width={40} height={40} />
                <div className="mal-10">
                  <p className="user-name">Rogelio Witting</p>
                  <p className="chat-date">12 November 2020 19:35</p>
                </div>
              </div>
              <div className="d-flex">
                <p className="reply-btn">Delete</p>
               <Link to="/chatlist-reply"> <p className="reply-btn">Reply</p></Link>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </div>
  );
}

export default CommunityChatView;
